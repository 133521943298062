import styled from 'react-emotion';
import { primary, secondary, alerts } from '@phoenix/all';

const ProofProgressStyled = styled('div')`
  .progress-item {
    font-weight: bold;
  }

  .orange {
    color: ${primary.orange()};
  }

  .red {
    color: ${alerts.error()};
  }

  .green {
    color: ${secondary.green()};
  }

  .gray {
    color: ${primary.gray()};
  }
`;

export { ProofProgressStyled };
