import React from 'react';
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Heading,
  Text,
} from '@adobe/react-spectrum';
import { closeDialog } from '../../../redux/dialog/dialog.actions';
import { clearSelectedItems } from '../../../redux/bulk-edit/bulk-edit.actions';
import { useDispatch, useSelector } from 'react-redux';
import { download } from '../../../api/documents';

const DownloadDialog = () => {
  const dispatch = useDispatch();
  const documentIDs = useSelector((state) => state.bulkEdit.selectedItems);
  const isBulkEditMode = useSelector((state) => state.bulkEdit.isBulkEditMode);


  const accept = () => {
    download(documentIDs)
    dispatch(closeDialog())
    if (isBulkEditMode) {
      dispatch(clearSelectedItems());
    }
  }
  const cancel = () => {
    dispatch(closeDialog())
  }

  return (
    <Dialog>
      <Heading>Download Document(s)</Heading>
      <Divider />
      <Content>
        <Text>
          {`Do you want to download ${documentIDs.length} document(s)?`}
        </Text>
      </Content>
      <ButtonGroup>
        <Button variant="cta" onPress={accept}>
          Accept
        </Button>
        <Button variant="secondary" onPress={cancel}>
          Cancel
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

export { DownloadDialog };
