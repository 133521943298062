import { StreamAPI, SpringController } from '@marvel/api';

export const getProofList = async (fileFilterId) => {
  return await StreamAPI.action({
    objCode: 'PROOF',
    queryString: `method=PUT&action=getProofList&fileFilterId=${fileFilterId}`,
  }).catch((error) => {
    console.log(`fail getting proof list: ${error}`);
  });
};

export const getProofAccessToken = async (proofID) => {
  return await StreamAPI.action({
    objCode: 'PROOF',
    queryString: `method=PUT&action=getProofAccessToken&proofID=${proofID}`,
  }).catch((error) => {
    console.log(`fail getting proof access token: ${error}`);
  });
};

export const getProofViewerConfig = async () => {
  return await StreamAPI.action({
    objCode: 'PROOF',
    queryString: `method=PUT&action=getProofViewerConfig`,
  }).catch((error) => {
    console.log(`fail getting proof viewer config: ${error}`);
  });
};

export const generateProof = async (documentVersionID) => {
  return await SpringController.post({
    url: '/document/proof/create',
    data: {
      documentVersionID,
    },
  });
};

export const getProofingWorkflowUrl = async (proofID) => {
  return await SpringController.get({
    url: `/getProofDetailsUrl?proofID=${proofID}`,
  });
};
