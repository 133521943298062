import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Heading,
  Radio,
  RadioGroup,
  Text,
  View,
} from '@adobe/react-spectrum';
import { toast } from '@phoenix/all';
import { closeDialog } from '../../../redux/dialog/dialog.actions';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFolderAndContents } from '../../../api/folders';

const DeleteFolderDialog = () => {
  const [deletionType, setDeletionType] = useState('keep-contents');
  const dispatch = useDispatch();
  const dialogProps = useSelector((state) => state.dialog.dialogProps);
  const folders = useSelector((state) => state.folder.folders);
  const folderContext = useSelector((state) => state.folder.folderContext);
  const folder = folders[dialogProps.folderID];
  const deleteFolder = () => {
    if (deletionType == 'keep-contents') {
      const promise = deleteFolder(folder.id);
      promise
        .catch((error) => {
          toast.error(error);
          dispatch(closeDialog());
        })
        .then((data) => {
          if (data.error) {
            toast.error(data.error.message);
          } else if (data.success) {
            toast(`Folder has been deleted.`);
            //TODO refresh folder list
          }
          dispatch(closeDialog());
        });
    } else if (deletionType == 'delete-contents') {
      const promise = deleteFolderAndContents(
        folder.id,
        folderContext.folderObjCode,
        folderContext.folderObjID
      );
      promise
        .catch((error) => {
          toast.error(error);
          dispatch(closeDialog());
        })
        .then((data) => {
          if (data.error) {
            toast.error(data.error.message);
          } else if (data.success) {
            toast(`Folder and contents have been deleted.`);
            //TODO refresh folder list
          }
          dispatch(closeDialog());
        });
    }
  };

  const cancel = () => {
    dispatch(closeDialog());
  };
  return (
    <Dialog>
      <Heading>Delete Folder</Heading>
      <Divider />
      <Content>
        <Text>
          You are deleting a folder that contains{' '}
          <b>{folder?.numberOfDocumentsContained} document(s)</b> and{' '}
          <b>{folder?.numberOfFoldersContained} folder(s).</b>
          <br />
          <br />
        </Text>
        <View marginStart={'15px'}>
          <Text> Would you like to:</Text>
          <RadioGroup value={deletionType} onChange={setDeletionType}>
            <Radio value="keep-contents">
              Delete the folder, and <b>keep</b> the contents
            </Radio>
            <Radio value="delete-contents">
              Delete the folder, and <b>delete</b> the contents
            </Radio>
          </RadioGroup>
        </View>
      </Content>

      <ButtonGroup>
        <Button variant="cta" onPress={deleteFolder}>
          {deletionType == 'keep-contents' ? 'Delete Folder' : 'Delete Folder & Contents'}
        </Button>
        <Button variant="secondary" onPress={cancel}>
          Cancel
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

export { DeleteFolderDialog };
