import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { View } from '@adobe/react-spectrum';
import { getBreadCrumbs, getDocumentProofTemplate } from '../../../api/documents';
import { Document } from 'workfront-objcodes';
import { overrideSelectedItems } from '../../../redux/bulk-edit/bulk-edit.actions';

import CardToolbar from './card-toolbar';
import CardThumbnail from './card-thumbnail';
import CardDocumentInfo from './card-document-info';
import CardBadge from './card-badge';

DocumentCard.propTypes = {
  idx: PropTypes.string,
  document: PropTypes.object,
  version: PropTypes.object,
};
function DocumentCard({ idx, document, version }) {
  const [breadcrumbs, setBreadcrumbs] = useState();
  const [headerVisible, setHeaderVisible] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(version);
  const [proofTemplateData, setProofTemplateData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [hasProof, setHasProof] = useState(!!version.proofID);
  const isCheckedIn = document.checkedOutByID ? false : true;
  const dispatch = useDispatch();
  const selectedItems = useSelector((state) => state.bulkEdit.selectedItems);
  const isSelected = selectedItems.includes(document.ID);

  useEffect(() => {
    getBreadCrumbs(document.ID, Document).then((result) => {
      setBreadcrumbs(result);
    });

    if (selectedVersion.proofToken) {
      getDocumentProofTemplate(selectedVersion.proofToken).then((result) => {
        setProofTemplateData(result);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getVersionData = async () => {
      if (selectedVersion.proofToken) {
        const proofData = await getDocumentProofTemplate(selectedVersion.proofToken);
        setProofTemplateData(proofData);
      }
    };
    getVersionData();
  }, [selectedVersion, hasProof]);

  // const canAddProof = () => {
  //   const {
  //     hasProofLicense,
  //     customer: { isProofingEnabled },
  //   } = currentUser;
  //   const { permissions } = selectedVersion;
  //   const canEdit = permissions.includes('EDIT') >= 0;

  //   return canEdit && isProofingEnabled && hasProofLicense;
  // };
  //TODO see if these need to be used
  // function selectCard() {
  //   if (isSelected) {
  //     dispatch(removeFromSelectedItems(document.ID));
  //   } else {
  //     if (isInBulkEditMode) {
  //       dispatch(addToSelectedItems(document.ID));
  //     } else {
  //       dispatch(overrideSelectedItems([document.ID]));
  //     }
  //   }
  // }

  // const handleProof = async (selection) => {
  //   const { ID: documentVersionID, documentID, uploadName } = selectedVersion;
  //   //TODO check if proof is done generating
  //   let tmpSelectedVersion;
  //   switch (selection) {
  //     case PROOF_TYPES.SIMPLE:
  //       await generateProof(documentVersionID);
  //       tmpSelectedVersion = selectedVersion;
  //       tmpSelectedVersion.proofStatus = 'inprogress';
  //       setSelectedVersion(tmpSelectedVersion);
  //       setHasProof(true);
  //       break;
  //     case PROOF_TYPES.AVANCED: {
  //       const returnUrl = encodeURIComponent('/documents');
  //       const documentThumbnailSrc = `/internal/document/thumbnail?size=MEDIUM&documentVersionID=${documentVersionID}`;
  //       const queryParams = {
  //         objCode: 'DOCU',
  //         objID: documentID,
  //         documentID,
  //         documentVersionID,
  //         uploadName,
  //         fromExistingDocument: true,
  //         documentThumbnailSrc,
  //         returnUrl,
  //       };
  //       history.push(`/document/proof/create/${QueryString.stringify(queryParams)}`);
  //       setHasProof(true);
  //       break;
  //     }
  //     default:
  //       console.error(`Unsupported proof type: ${selection}`);
  //   }
  // };

  const selectItem = () => {
    dispatch(overrideSelectedItems([document.ID]));
  };

  return (
    <View
      borderWidth="thick"
      borderRadius="regular"
      width={'size-3600'}
      backgroundColor={'gray-50'}
      borderColor={'gray-200'}
      position={'relative'}
    >
      <CardBadge
        document={document}
        selectedVersion={selectedVersion}
        proofTemplateData={proofTemplateData}
      />
      <div
        role="button"
        tabIndex={idx}
        onMouseEnter={() => {
          setHeaderVisible(true);
        }}
        onMouseLeave={() => {
          setHeaderVisible(false);
        }}
      >
        <div
          style={{
            marginBottom: '16px',
            cursor: 'pointer',
            overflow: 'hidden',
            overflowWrap: 'break-word',
            width: '100%',
          }}
        >
          <CardToolbar
            visible={isSelected ? isSelected : headerVisible}
            selectItem={selectItem}
            selectedVersion={selectedVersion}
            document={document}
            hasProof={hasProof}
            isCheckedIn={isCheckedIn}
            breadcrumbs={breadcrumbs}
          />
          <CardThumbnail
            document={document}
            selectedVersion={selectedVersion}
            breadcrumbs={breadcrumbs}
          />
          <CardDocumentInfo
            proofTemplateData={proofTemplateData}
            selectedVersion={selectedVersion}
            document={document}
            setSelectedVersion={setSelectedVersion}
          />
        </div>
      </div>
    </View>
  );
}

export { DocumentCard };
