import React from 'react';
import PropTypes from 'prop-types';
import { Item, Picker, Text } from '@adobe/react-spectrum';
import { useDispatch, useSelector } from 'react-redux';
import { PRESET_CONTEXTS } from '../../../../utils/constants';
import { setRootContext } from '../../../../../redux/view/view.actions';
import {
  closeFolder,
  openFolder,
  setSelectedFolder,
} from '../../../../../redux/folders/folder.actions';
import { getFolderContents } from '../../../../../api/folders';
import FolderOutline from '@spectrum-icons/workflow/FolderOutline';

function RootContextSelector() {
  const rootContext = useSelector((state) => state.view.rootContext);
  const selectedFolder = useSelector((state) => state.folder.selectedFolder);
  const folders = useSelector((state) => state.folder.folders);
  const folderObjCode = useSelector((state) => state.folder.folderContext.folderObjCode);
  const folderObjID = useSelector((state) => state.folder.folderContext.folderObjID);

  const dispatch = useDispatch();
  // return <div>{rootContext.type == 'folder' ? selectedFolder.name : rootContext.label}</div>
  return (
    <Picker
      onSelectionChange={(key) => {
        if (Object.keys(PRESET_CONTEXTS).includes(key)) {
          dispatch(
            setRootContext({
              type: key,
              subType: key == 'approval' ? 'version' : null,
              label: PRESET_CONTEXTS[key],
            })
          );
          dispatch(setSelectedFolder({}));
        } else {
          dispatch(setSelectedFolder(folders[key]));
          dispatch(
            setRootContext({
              type: 'folder',
              subType: null,
              label: folders[key].name,
            })
          );
          if (folders[key].open) {
            dispatch(closeFolder(key));
          } else {
            getFolderContents(folderObjCode, folderObjID, 1, 50, key, 'asc', 'name').then(
              (result) => {
                dispatch(openFolder(key, result));
              }
            );
          }
        }
      }}
      isQuiet
      selectedKey={rootContext.type != 'folder' ? rootContext.type : selectedFolder.id}
    >
      {Object.keys(PRESET_CONTEXTS).map((key) => (
        <Item key={key}>{PRESET_CONTEXTS[key]}</Item>
      ))}
      {folders &&
        folders.topLevelFolders &&
        folders.topLevelFolders.length > 0 &&
        folders.topLevelFolders.map((folderID) => (
          <Item key={folderID}>
            <Text UNSAFE_style={{ display: 'flex', alignItems: 'center' }}>
              <FolderOutline />
              {folders[folderID].name}
            </Text>
          </Item>
        ))}
    </Picker>
  );
}

export { RootContextSelector };

RootContextSelector.propTypes = {
  folder: PropTypes.object,
  indent: PropTypes.number,
  handleClick: PropTypes.func,
};
