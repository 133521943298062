import { searchActionTypes } from './search.types';

const initalState = {
  searchTerm: null,
};

const searchReducer = (state = initalState, action) => {
  switch (action.type) {
    case searchActionTypes.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      };
    default:
      return state;
  }
};

export default searchReducer;
