import React from 'react';
import { ActionButton } from '@adobe/react-spectrum';
import StarOutline from '@spectrum-icons/workflow/StarOutline';
import Star from '@spectrum-icons/workflow/Star';
import { useDispatch, useSelector } from 'react-redux';
import { addFavorite, getAllFavorties, removeFavorite } from '../../../../api/favorites';
import { setFavorites } from '../../../../redux/favorite/favorite.actions';
import { removeDocument } from '../../../../redux/document/document.actions';
import { toast } from '@phoenix/all';
import PropTypes from 'prop-types';

const FavoriteButton = ({ documentID, documentName }) => {
  const favorites = useSelector((state) => state.favorite.favorites);
  const rootContext = useSelector((state) => state.view.rootContext);

  const isFavorited = favorites.includes(documentID);
  const dispatch = useDispatch();

  async function handleClick() {
    if (isFavorited) {
      await removeFavorite(documentID, documentName);
      toast.success(`Favorite Removed for ${documentName}`, { duration: 2 });
    } else {
      await addFavorite(documentID, documentName);
      toast.success(`Favorite Added for ${documentName}`, { duration: 2 });
    }
    getAllFavorties()
      .then((result) => {
        dispatch(setFavorites(result));
      })
      .then(() => {
        //If we are on the favorites preset we should reload the list of documents
        if (rootContext.type == 'favorite') {
          dispatch(removeDocument(documentID));
        }
      });
  }
  return (
    <ActionButton onPress={handleClick} UNSAFE_style={{ cursor: 'pointer' }}>
      {isFavorited ? <Star size="S" color="informative" /> : <StarOutline size="S" />}
    </ActionButton>
  );
};

FavoriteButton.propTypes = {
  documentID: PropTypes.string,
  documentName: PropTypes.string,
};

export default FavoriteButton;
