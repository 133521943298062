import React from 'react';
import { ActionEnum } from '@workfront/panel-components';
import { css } from 'react-emotion';
import { Filters } from '@workfront/filters-ui';
import { convert } from '@workfront/filter-redrock-integration';
import { useCurrentUser } from '@wf-mfe/auth';
import {
  QUICK_FILTER_FIELDS,
  ADVANCED_FILTER_FIELD_GROUPS,
  CUSTOM_QUARTERS,
} from '../../../utils/filters';
import { useDispatch } from 'react-redux';
import { setShowFilters, setTritonFilters } from '../../../../redux/filter/filter.actions';

function FilterWrapper() {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  return (
    <div id="filters-container" className={filtersContainer}>
      <Filters
        isOperatorsDisabled
        isAutoApply
        isTextModeSupported
        filterKey={'documents-dashboard'}
        quickFilterFields={QUICK_FILTER_FIELDS}
        advancedFieldGroups={ADVANCED_FILTER_FIELD_GROUPS}
        applyFiltersCallback={(appliedFilters) => {
          if (appliedFilters && appliedFilters.length > 0) {
            convert(appliedFilters).then((result) => {
              dispatch(setTritonFilters(result));
            });
          } else {
            dispatch(setTritonFilters({}));
          }
        }}
        onCloseCallback={() => {
          dispatch(setShowFilters(false));
        }}
        currentUser={{
          ID: currentUser.ID,
          name: currentUser.name,
          isAdmin: currentUser.accessLevel.isAdmin,
          coreAction: ActionEnum.VIEW,
        }}
        subtitle="My filter"
        customQuarters={CUSTOM_QUARTERS}
      />
    </div>
  );
}

export { FilterWrapper };

const filtersContainer = css`
  width: 472px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
`;
