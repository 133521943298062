import React from 'react';

import {
  PreviewDialog,
  MoveDialog,
  TestDialog,
  EditDialog,
  DefaultDialog,
  DeleteDialog,
  DownloadDialog,
  ShareProofDialog,
} from '..';
import { DIALOG_TYPES } from '../../utils/constants';
import { DeleteFolderDialog } from '../delete-folder-dialog';
import { WorkflowDialog } from '../workflow-dialog';

export const dialogSwitch = (dialogID) => {
  switch (dialogID) {
    case DIALOG_TYPES.delete:
      return <DeleteDialog />;
    case DIALOG_TYPES.deleteFolder:
      return <DeleteFolderDialog />;
    case DIALOG_TYPES.preview:
      return <PreviewDialog />;
    case DIALOG_TYPES.edit:
      return <EditDialog />;
    case DIALOG_TYPES.move:
      return <MoveDialog />;
    case DIALOG_TYPES.download:
      return <DownloadDialog />;
    case DIALOG_TYPES.test:
      return <TestDialog />;
    case DIALOG_TYPES.shareProof:
      return <ShareProofDialog />;
    case DIALOG_TYPES.workflow:
      return <WorkflowDialog />;
    default:
      return <DefaultDialog />;
  }
};
