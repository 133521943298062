import React from 'react';
import formatBytesToSize, { getStageColor } from './helper';
import { ProofProgress } from '../shared/document-card/proof-progress';
import { ProofStages } from '../shared/proof-stages';
import FileUser from '@spectrum-icons/workflow/FileUser';
import { Text } from '@adobe/react-spectrum';
import Document from '@spectrum-icons/workflow/Document';
import Image from '@spectrum-icons/workflow/Image';
import VideoFilled from '@spectrum-icons/workflow/VideoFilled';
import FileCode from '@spectrum-icons/workflow/FileCode';
import Audio from '@spectrum-icons/workflow/Audio';
import FileZip from '@spectrum-icons/workflow/FileZip';
import FileEmail from '@spectrum-icons/workflow/FileEmail';
import FileHTML from '@spectrum-icons/workflow/FileHTML';
import FileJson from '@spectrum-icons/workflow/FileJson';
import FileCSV from '@spectrum-icons/workflow/FileCSV';

export const FILTER_KEYS = {
  parent_object_project: 'parent_object_project',
  parent_object_task: 'parent_object_task',
  parent_object_issue: 'parent_object_issue',
  parent_object_user: 'parent_object_user',
  proofs_is_proof: 'proofs_is_proof',
};

export const PAGINATION_LIMIT = 24;
export const DOUBLE_CLICK_WINDOW = 200;

export const PRESET_CONTEXTS = {
  all: 'All Documents',
  recent: 'Recents',
  favorite: 'Favorites',
  approval: 'Approvals',
};

export const DIALOG_TYPES = {
  open: 'open',
  delete: 'delete',
  deleteFolder: 'delete-folder',
  download: 'download',
  move: 'move',
  edit: 'edit',
  shareProof: 'share-proof',
  preview: 'preview',
  workflow: 'workflow',
  test: 'test',
};

export const VIEW_TYPES = {
  summary: 'summary',
  content: 'content',
  tree: 'tree',
};

export const SORT_OPTIONS = {
  name: 'Document Name',
  lastUpdateDate: 'Last Updated Date',
  lastModDate: 'Last Modified Date',
  ['owner:name']: 'Owner Name',
  referenceNumber: 'Reference Number',
};

export const DOCUMENT_FIELDS = {
  ['owner:name']: {
    label: 'Owner',
    getAttribute: (doc) => {
      return (
        <>
          <FileUser size="XS" />
          <Text>{doc?.owner?.name}</Text>
        </>
      );
    },
  },
  referenceNumber: { label: 'Reference Number' },
};

export const VERSION_FIELDS = {
  docSize: {
    label: 'Size',
    getAttribute: (version) => {
      return formatBytesToSize(version.docSize);
    },
  },
};

export const PROOF_FIELDS = {
  overall_decision: {
    label: 'Version Decision',
    getAttribute: (selectedVersion, proofData) => {
      return <ProofProgress progress={proofData.progress} />;
    },
  },
  recipient_decision: {
    label: 'My Decision',
    getAttribute: (selectedVersion, proofData) => {
      return (
        <>
          <Document size="XS"></Document>
          <Text
            color={getStageColor(proofData?.decision?.name)}
            UNSAFE_style={{ fontSize: '12px' }}
          >
            {proofData?.decision?.displayName
              ? proofData?.decision?.displayName
              : 'No Decision Required'}
          </Text>
        </>
      );
    },
  },
  id: {
    label: 'Proof ID',
    getAttribute: (selectedVersion, proofData) => (proofData?.id ? proofData?.id : 'No Id'),
  },
  progress: {
    label: 'Progress',
    getAttribute: (selectedVersion, proofData) => {
      return <ProofProgress progress={proofData?.progress} />;
    },
  },
  stages: {
    label: 'Stages',
    getAttribute: (selectedVersion, proofData) => {
      return <>{proofData && proofData.stages && <ProofStages stages={proofData.stages} />}</>;
    },
  },
};

export const FOLDER_OFFSET = 20;

export function getFileTypeIcon(ext) {
  switch (ext) {
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'svg':
      return <Image size="S" />;
    case 'mp4':
    case 'mov':
    case 'avi':
    case 'wmv':
    case 'avchd':
    case 'flv':
    case 'fv4':
    case 'swf':
    case 'mkv':
    case 'webm':
    case 'mpeg-2':
      return <VideoFilled size="S" />;
    case 'aif':
    case 'cda':
    case 'mid':
    case 'midi':
    case 'mp3':
    case 'mpa':
    case 'ogg':
    case 'wav':
    case 'wma':
    case 'wpi':
      return <Audio size="S" />;
    case 'zip':
    case '7z':
    case 'arj':
    case 'deb':
    case 'pkg':
    case 'rar':
    case 'rpm':
    case 'tar.gz':
    case 'z':
      return <FileZip size="S" />;
    case 'email':
    case 'eml':
    case 'emlx':
    case 'msg':
    case 'oft':
    case 'ost':
    case 'pst':
    case 'vcf':
      return <FileEmail size="S" />;
    case 'csv':
    case 'xlsx':
      return <FileCSV size="S" />;
    case 'html':
      return <FileHTML size="S" />;
    case 'json':
      return <FileJson size="S" />;
    case 'c':
    case 'css':
    case 'style':
    case 'java':
    case 'php':
    case 'py':
      return <FileCode size="S" />;
    default:
      return <Document size="S" />;
  }
}
