import React, { useEffect, useState } from 'react';
import { Content, Dialog, Image } from '@adobe/react-spectrum';
import { getProofAccessToken, getProofViewerConfig } from '../../../api/proofs';
import { Breadcrumb } from '../../shared/breadcrumbs';
import { useSelector } from 'react-redux';

function PreviewDialog() {
  const [proofOpen, setProofOpen] = useState(false);
  // const [proofAccessToken, setProofAccessToken] = useState(true);
  const [proofViewerConfig, setProofViewerConfig] = useState(true);
  const [viewerLoaded, setViewerLoaded] = useState(true);

  const dialogProps = useSelector((state) => state.dialog.dialogProps);
  const { document, version, breadcrumbs } = dialogProps;

  const IFRAME_ID = `proof-viewer-iframe-${version?.ID}`;
  useEffect(() => {
    handleOpenProof();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleOpenProof() {
    if (!proofOpen) {
      setProofOpen(true);
      if (version?.proofID) {
        window.addEventListener('message', onMessage, false);
        getProofViewerConfig().then((result) => {
          setProofViewerConfig(
            result.proofViewer.url.replace('index.html', 'index.integration.html')
          );
          getProofAccessToken(version?.proofID).then((result) => {
            // setProofAccessToken(result);
            const accessData = {
              ...result,
              disableRouter: true,
              // locale: currentUser.locale,
            };
            callMethodInViewer('openProof', accessData);
            notifyViewer();
          });
        });
      }
    } else {
      setProofOpen(false);
    }
  }

  const notifyViewer = () => {
    if (viewerLoaded) {
      var iframeWin = window.document.querySelector(`#${IFRAME_ID}`);
      const contentWindow = iframeWin.contentWindow;
      const message = {
        type: 'QSdata',
        data: {
          quicksilver: true,
          breadcrumbs: breadcrumbs,
          permissions: {
            showCloseButton: true,
          },
          settingsLink: getSettingsLink(),
        },
      };
      contentWindow.postMessage(message, '*');
    }
  };

  function getSettingsLink() {
    const objID = document?.ID;
    const versionID = version?.ID;
    return `${window.location.origin}/document/${objID}/${versionID}/proofing-workflow`;
  }

  const callMethodInViewer = (methodName, params) => {
    if (viewerLoaded) {
      var iframeWin = window.document.querySelector(`#${IFRAME_ID}`);
      const contentWindow = iframeWin.contentWindow;
      const message = {
        type: methodName,
        params: params,
      };
      contentWindow.postMessage(message, '*');
    }
  };

  const onMessage = (event) => {
    if (event.data === 'proof-viewer-ready') {
      setViewerLoaded(true);
      return;
    }
    const { source } = event.data;

    if (source === 'closeViewer') {
      setProofOpen(false);
      return;
    }
  };

  if (document || version || breadcrumbs) {
    return (
      <Dialog width={'90vw'} height={'85vh'} isDismissable>
        <Content>
          <Breadcrumb document={document} breadcrumbs={breadcrumbs} />
          {version?.proofID && (
            <iframe
              style={{ width: '100%', height: '100%' }}
              id={IFRAME_ID}
              title="proof-viewer-iframe"
              src={proofViewerConfig}
            />
          )}
          {!version?.proofID && (
            <Image
              width="100%"
              height="100%"
              src={`/internal/document/thumbnail?build=${new Date().getTime()}&size=ORIGINAL&documentVersionID=${
                version?.ID
              }`}
            />
          )}
        </Content>
      </Dialog>
    );
  } else {
    return null;
  }
}

export { PreviewDialog };
