import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ActionButton, Flex, Item, Menu, MenuTrigger, ToggleButton } from '@adobe/react-spectrum';
import FolderOutline from '@spectrum-icons/workflow/FolderOutline';
import FolderOpenOutline from '@spectrum-icons/workflow/FolderOpenOutline';
import ChevronUp from '@spectrum-icons/workflow/ChevronUp';
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';
import More from '@spectrum-icons/workflow/More';
import { useSelector, useDispatch } from 'react-redux';
import { openDialog } from '../../../../redux/dialog/dialog.actions';
import { NewFolderItem } from '../new-folder-item';

function FolderItem({ folder, indent, handleClick }) {
  const [newFolderVisible, setNewFolderVisible] = useState(false);
  const selectedFolder = useSelector((state) => state.folder.selectedFolder);
  const dispatch = useDispatch();
  return (
    <>
      <ToggleButton
        width={'100%'}
        isQuiet
        isSelected={selectedFolder.id == folder.id}
        onClick={handleClick}
        UNSAFE_style={{ justifyContent: 'left', paddingLeft: indent }}
      >
        <Flex alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
          <Flex alignItems={'center'} gap={'5px'}>
            {folder.open ? <ChevronDown /> : <ChevronUp />}
            {folder.open ? <FolderOpenOutline /> : <FolderOutline />}
            <b>{folder.name}</b> (
            {folder.numberOfDocumentsContained + folder.numberOfFoldersContained})
          </Flex>
          <MenuTrigger type="popover">
            <ActionButton isQuiet UNSAFE_style={{ cursor: 'pointer' }}>
              <More aria-label="More" size="S" />
            </ActionButton>
            <Menu
              onAction={(e) => {
                console.log(e);
                switch (e) {
                  case 'delete':
                    dispatch(openDialog('delete-folder', { folderID: folder.id }));
                    break;
                  case 'subfolder':
                    setNewFolderVisible(true);
                    break;
                }
              }}
            >
              <Item key="rename">Rename</Item>
              <Item key="delete">Delete</Item>
              <Item key="share">Share</Item>
              <Item key="subfolder">Add Subfolder</Item>
            </Menu>
          </MenuTrigger>
        </Flex>
      </ToggleButton>
      {newFolderVisible && (
        <NewFolderItem
          parentFolderID={folder.id}
          onClose={() => {
            setNewFolderVisible(false);
          }}
        ></NewFolderItem>
      )}
    </>
  );
}

export { FolderItem };

FolderItem.propTypes = {
  folder: PropTypes.object,
  indent: PropTypes.number,
  handleClick: PropTypes.func,
};
