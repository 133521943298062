import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@adobe/react-spectrum';
import { VERSION_FIELDS } from '../../../utils/constants';

const CardDocumentVersionInfoField = ({ option, selectedVersion }) => {
  return VERSION_FIELDS[option] ? (
    <View>
      {VERSION_FIELDS[option]?.getAttribute
        ? VERSION_FIELDS[option].getAttribute(selectedVersion)
        : selectedVersion[option]}
    </View>
  ) : (
    <></>
  );
};

export default CardDocumentVersionInfoField;

CardDocumentVersionInfoField.propTypes = {
  option: PropTypes.string,
  selectedVersion: PropTypes.object,
};
