import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs, Item, View } from '@adobe/react-spectrum';
import { Task, OpTask, Project, Portfolio, Program, User } from 'workfront-objcodes';
import LockClosed from '@spectrum-icons/workflow/LockClosed';
import { useHistory } from 'react-router-dom';

function Breadcrumb({ document, breadcrumbs, isMultiline }) {
  const history = useHistory();

  const isCheckedIn = document.checkedOutByID ? false : true;

  return (
    <>
      {breadcrumbs && breadcrumbs.length > 0 && (
        <View overflow="hidden" width="100%">
          <Breadcrumbs isMultiline={isMultiline} onAction={(a) => history.push(a)} size="S">
            {breadcrumbs.map((crumb) => (
              <Item key={crumb.link}>{`(${getObjLabel(crumb.objCode)}) ${crumb.name}`}</Item>
            ))}
            <Item key={'/documents/' + document.id}>
              <>
                <span
                  style={{
                    color: '#0645AD',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {document.name}
                </span>
                {isCheckedIn ? null : <LockClosed size={'XS'} marginStart={'size-100'} />}
              </>
            </Item>
          </Breadcrumbs>
        </View>
      )}
    </>
  );
}

function getObjLabel(objCode) {
  switch (objCode) {
    case User:
      return 'User';
    case Project:
      return 'Project';
    case OpTask:
      return 'Issue';
    case Task:
      return 'Task';
    case Program:
      return 'Program';
    case Portfolio:
      return 'Portfolio';
    default:
      return '';
  }
}

export { Breadcrumb };

Breadcrumb.propTypes = {
  document: PropTypes.object,
  breadcrumbs: PropTypes.array,
  isMultiline: PropTypes.bool,
};
