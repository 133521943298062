import { viewActionTypes } from './view.types';

export const setContentView = () => ({
  type: viewActionTypes.VIEW_CONTENT_ONLY,
});
export const setTreeView = () => ({
  type: viewActionTypes.VIEW_CONTENT_TREE,
});
export const setSummaryView = () => ({
  type: viewActionTypes.VIEW_SUMMARY,
});
export const updateViewOptions = (options) => ({
  type: viewActionTypes.DOCUMENT_INFO_FIELDS,
  payload: [...options],
});
export const setListType = (type) => ({
  type: viewActionTypes.SET_LIST_TYPE,
  payload: type,
});
export const setRootContext = (context) => ({
  type: viewActionTypes.SET_ROOT_CONTEXT,
  payload: context,
});
