import React, { useEffect, useRef, useState } from 'react';
import { Content, Dialog, ProgressCircle } from '@adobe/react-spectrum';
import styled from 'react-emotion';
import { getProofingWorkflowUrl } from '../../../api';
import { useSelector } from 'react-redux';

function WorkflowDialog() {
  const [proofingWorkflowUrl, setProofingWorkflowUrl] = useState('');
  const [isLoading, setLoading] = useState(true);
  const iframeElem = useRef(null);
  const dialogProps = useSelector((state) => state.dialog.dialogProps);
  const proofID = dialogProps.proofID;
  const onLoad = () => {
    setLoading(false);
  };

  const IframeContainerStyled = styled('div')`
    width: 100%;
    flex: 1;

    iframe {
      display: block;
      width: 100%;
      height: calc(100vh - 170px);
      overflow: auto;
    }
  `;
  useEffect(() => {
    if (proofID) {
      getProofingWorkflowUrl(proofID).then((result) => {
        //TODO do validation of result and error handling
        setProofingWorkflowUrl(`${result.proofDetailsUrl}&section=workflow`);
      });
    }
  }, [proofID]);

  return (
    <Dialog width={'90vw'} height={'85vh'} isDismissable>
      <Content>
        {isLoading ? <ProgressCircle /> : null}
        {proofID && proofingWorkflowUrl ? (
          <IframeContainerStyled>
            <iframe
              id="workflow-frame"
              src={proofingWorkflowUrl}
              ref={iframeElem}
              onLoad={onLoad}
              title="Proofing workflow"
            />
          </IframeContainerStyled>
        ) : null}
      </Content>
    </Dialog>
  );
}

export { WorkflowDialog };
