export default function formatBytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '';
  const i = Number.parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

export function dateFromString(date) {
  if (!date || date === '' || date === 'undefined') {
    return;
  }

  let a = date.split(/\D/);
  let formattedDate = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
  let options = { year: 'numeric', month: 'short', day: 'numeric' };
  let ampm = formattedDate.getHours() >= 12 ? 'pm' : 'am';

  return (
    formattedDate.toLocaleDateString('en-US', options) +
    ' at ' +
    formattedDate.getHours() +
    ':' +
    formattedDate.getMinutes() +
    ' ' +
    ampm
  );
}

export function getHumanTime(date) {
  if (!date || date === '' || date === 'undefined') {
    return;
  }
  let a = date.split(/\D/);
  let formattedDate = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
  // Convert to a positive integer
  let now = new Date().getTime();
  let time = Math.abs(now - formattedDate.getTime());

  // Define humanTime and units
  let humanTime, units;

  // If there are years
  if (time > 1000 * 60 * 60 * 24 * 365) {
    humanTime = Number.parseInt(time / (1000 * 60 * 60 * 24 * 365), 10);
    units = 'years';
  }

  // If there are months
  else if (time > 1000 * 60 * 60 * 24 * 30) {
    humanTime = Number.parseInt(time / (1000 * 60 * 60 * 24 * 30), 10);
    units = 'months';
  }

  // If there are weeks
  else if (time > 1000 * 60 * 60 * 24 * 7) {
    humanTime = Number.parseInt(time / (1000 * 60 * 60 * 24 * 7), 10);
    units = 'weeks';
  }

  // If there are days
  else if (time > 1000 * 60 * 60 * 24) {
    humanTime = Number.parseInt(time / (1000 * 60 * 60 * 24), 10);
    units = 'days';
  }

  // If there are hours
  else if (time > 1000 * 60 * 60) {
    humanTime = Number.parseInt(time / (1000 * 60 * 60), 10);
    units = 'hours';
  }

  // If there are minutes
  else if (time > 1000 * 60) {
    humanTime = Number.parseInt(time / (1000 * 60), 10);
    units = 'minutes';
  }

  // Otherwise, use seconds
  else {
    humanTime = Number.parseInt(time / 1000, 10);
    units = 'seconds';
  }

  return humanTime + ' ' + units;
}

export const getStageColor = (decisionName) => {
  switch (decisionName) {
    case 'Pending':
      return 'neutral';
    case 'Approved with changes':
      return 'yellow';
    case 'Not relevant':
      return 'indigo';
    case 'Approved':
      return 'positive';
    case 'Changes required':
      return 'negative';
    default:
      return 'neutral';
  }
};

// export function getProofFilters(filters) {
//   let documentsIds = [];
//   if (filters.includes('proofs')) {
//     filters = { proofID_Mod: 'notblank' };
//     getDocumentVersions(filters, ['documentID']).then((result) => {
//       documentsIds = [...documentsIds, ...result];
//     });
//   } else if (filters.includes('active')) {
//     getProofList(1).then((result) => {
//       filters = { proofID_Mod: 'in', proofID: result };
//       getDocumentVersions(filter, ['documentID']).then((result) => {
//         documentsIds = [...documentsIds, ...result];
//       });
//     });
//   } else if (filters.includes('locked')) {
//     getProofList(2).then((result) => {
//       filters = { proofID_Mod: 'in', proofID: result };
//       getDocumentVersions(filters, ['documentID']).then((result) => {
//         documentsIds = [...documentsIds, ...result];
//       });
//     });
//   } else if (filters.includes('archived')) {
//     getProofList(3).then((result) => {
//       filters = { proofID_Mod: 'in', proofID: result };
//       getDocumentVersions(filters, ['documentID']).then((result) => {
//         documentsIds = [...documentsIds, ...result];
//       });
//     });
//   } else if (filters.includes('tomanage')) {
//     getProofList(4).then((result) => {
//       filters = { proofID_Mod: 'in', proofID: result };
//       getDocumentVersions(filters, ['documentID']).then((result) => {
//         documentsIds = [...documentsIds, ...result];
//       });
//     });
//   } else if (filters.includes('awaiting')) {
//     getProofList(5).then((result) => {
//       filters = { proofID_Mod: 'in', proofID: result };
//       getDocumentVersions(filters, ['documentID']).then((result) => {
//         documentsIds = [...documentsIds, ...result];
//       });
//     });
//   } else if (filters.includes('late')) {
//     getProofList(6).then((result) => {
//       filters = { proofID_Mod: 'in', proofID: result };
//       getDocumentVersions(filters, ['documentID']).then((result) => {
//         documentsIds = [...documentsIds, ...result];
//       });
//     });
//   }
//   return { ID: documentsIds, ID_Mod: 'in' };
// }
