import { sortActionTypes } from './sort.types';

const initalState = {
  sortType: localStorage.getItem('document-dashboard-sort-type')
    ? localStorage.getItem('document-dashboard-sort-type')
    : 'name',
  sortDirection: localStorage.getItem('document-dashboard-sort-direction')
    ? localStorage.getItem('document-dashboard-sort-direction')
    : 'asc',
};

const sortReducer = (state = initalState, action) => {
  switch (action.type) {
    case sortActionTypes.SET_SORT_TYPE:
      return {
        ...state,
        sortType: action.payload,
      };
    case sortActionTypes.SET_SORT_DIRECTION:
      return {
        ...state,
        sortDirection: action.payload,
      };

    default:
      return state;
  }
};

export default sortReducer;
