import React, { useEffect, Suspense } from 'react';
import { Flex, View, ProgressCircle } from '@adobe/react-spectrum';
import { getDocuments } from '../../../api/documents';
import { DocumentCard } from '../../shared/document-card';
import { FilterWrapper } from './filter-wrapper';
import { PAGINATION_LIMIT, VIEW_TYPES } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DocumentTable } from '../../shared/document-table';
import { FolderSidebar } from './folder-sidebar';
import { useCurrentUser } from '@wf-mfe/auth';
import { Document } from 'workfront-objcodes';
import FocusTrap from 'focus-trap-react';
import { css, cx } from 'react-emotion';
import { setContentView } from '../../../redux/view/view.actions';
import { clearSelectedItems } from '../../../redux/bulk-edit/bulk-edit.actions';
import { DashboardSubHeader } from './dashboard-sub-header';
import { getRootContextFilters } from '../../utils/filters';
import {
  clearDocuments,
  setDocuments,
  setLoading,
  setHasMore,
  setFirstValue,
} from '../../../redux/document/document.actions';
import {
  setSearchFilters,
  setBaseFilters,
  setSortFilters,
} from '../../../redux/filter/filter.actions';

//TODO this file is a mess and could be cleaned up
export const DashboardBody = ({ width }) => {
  const isRecent = useSelector((state) => state.document.isRecent);
  const rootContext = useSelector((state) => state.view.rootContext);
  const selectedFolder = useSelector((state) => state.folder.selectedFolder);
  const listType = useSelector((state) => state.view.listType);
  const documents = useSelector((state) => Object.values(state.document.documents));
  const searchFilters = useSelector((state) => state.filter.searchFilters);
  const baseFilters = useSelector((state) => state.filter.baseFilters);
  const tritonFilters = useSelector((state) => state.filter.tritonFilters);
  const sortFilters = useSelector((state) => state.filter.sortFilters);
  const loading = useSelector((state) => state.document.loading);
  const hasMore = useSelector((state) => state.document.hasMore);
  const firstValue = useSelector((state) => state.document.firstValue);
  const showFilters = useSelector((state) => state.filter.showFilters);
  const sortType = useSelector((state) => state.sort.sortType);
  const sortDirection = useSelector((state) => state.sort.sortDirection);
  const searchTerm = useSelector((state) => state.search.searchTerm);
  const favorites = useSelector((state) => state.favorite.favorites);

  const currentUser = useCurrentUser();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSortFilters({ [`${sortType}_Sort`]: sortDirection }));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setLoading(true));
    resetOffset();
    dispatch(clearDocuments());
    getRootContextFilters(rootContext, selectedFolder.id, currentUser.ID, favorites).then(
      (result) => {
        getDocuments({
          ...result,
          ...tritonFilters,
          ...baseFilters,
          ...searchFilters,
          // ...sortFilters, #TODO THIS IS KILLING OUR LOAD TIME
        }).then((result) => {
          dispatch(setHasMore(result.length === PAGINATION_LIMIT));
          const documentsCopy = [...result];
          dispatch(setDocuments(documentsCopy));
          dispatch(setLoading(false));
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tritonFilters, searchFilters, sortFilters, isRecent, rootContext]);

  useEffect(() => {
    if (firstValue > 0) {
      dispatch(setLoading(true));
      getRootContextFilters(rootContext, selectedFolder.id, currentUser.ID, favorites).then(
        (result) => {
          getDocuments({
            ...result,
            ...tritonFilters,
            ...baseFilters,
            ...searchFilters,
            // ...sortFilters, #TODO THIS IS KILLING OUR LOAD TIME
          }).then((result) => {
            dispatch(setHasMore(result.length === PAGINATION_LIMIT));
            const documentsCopy = [...documents, ...result];
            dispatch(setDocuments(documentsCopy));
            dispatch(setLoading(false));
          });
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstValue]);

  useEffect(() => {
    if (searchTerm) {
      dispatch(
        setSearchFilters({
          name: `${searchTerm}%`,
          name_Mod: 'cilike',
        })
      );
    } else {
      if (
        searchFilters && // 👈 null and undefined check
        Object.getPrototypeOf(searchFilters) === Object.prototype &&
        Object.keys(searchFilters).length > 0
      ) {
        dispatch(setSearchFilters({}));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    const newSortFilters = { [`${sortType}_Sort`]: sortDirection };
    if (JSON.stringify(newSortFilters) !== JSON.stringify(sortFilters))
      dispatch(setSortFilters(newSortFilters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType, sortDirection]);

  const increaseOffset = () => {
    console.log('increasing');
    const filtersCopy = { ...baseFilters };
    dispatch(setFirstValue(filtersCopy['$$FIRST'] + filtersCopy['$$LIMIT']));
    filtersCopy['$$FIRST'] = filtersCopy['$$FIRST'] + filtersCopy['$$LIMIT'];
    dispatch(setBaseFilters(filtersCopy));
  };

  const resetOffset = () => {
    const filtersCopy = { ...baseFilters };
    filtersCopy['$$FIRST'] = 0;
    dispatch(setFirstValue(0));
    dispatch(setBaseFilters(filtersCopy));
  };

  function retryOnFail(fn, retriesLeft = 5, interval = 300) {
    return new Promise((resolve, reject) => {
      fn()
        .then(resolve)
        .catch((error) => {
          setTimeout(() => {
            if (retriesLeft === 1) {
              // reject('maximum retries exceeded');
              reject(error);
              return;
            }

            // Passing on "reject" is the important part
            retryOnFail(fn, retriesLeft - 1, interval).then(resolve, reject);
          }, interval);
        });
    });
  }

  //TODO I don't know if this is necessary this is just how they load minix in quicksilver, it seems a little slow to me so maybe we can optimize it
  const Minix = React.lazy(() =>
    retryOnFail(() =>
      // eslint-disable-next-line no-undef
      System.import('@wf-mfe/minix').then((minixMFE) => {
        // React Lazy expects the component to be the Default export so we're making it look like a default export for React.lazy
        return { default: minixMFE.MinixInnerContainer };
      })
    )
  );

  const WIDTH_SMALL = 360;
  const WIDTH_LARGE = 472;

  const miniXContainerClassName = css`
    background: white;
    box-shadow: 0 3px 6px 0 rgba(0, 35, 64, 0.2);
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    height: 100%;
    overflow: scroll;
    z-index: 7;
    #minixHeader {
      display: none;
    }
  `;

  const miniXSizeClassName = {
    large: css`
      width: ${WIDTH_LARGE}px;
    `,
    small: css`
      width: ${WIDTH_SMALL}px;
    `,
  };

  const selectedItems = useSelector((state) => state.bulkEdit.selectedItems);

  const waitForElement = ({ selector, container }) => {
    return new Promise((resolve, reject) => {
      const parentNode = container || document;

      if (parentNode.querySelector(selector)) {
        return resolve(parentNode.querySelector(selector));
      }

      const timeout = setTimeout(() => {
        reject('Element Unavailable');
        observer?.disconnect();
        //eslint-disable-next-line no-undef
      }, waitForElementTimeout);

      const observer = new MutationObserver(() => {
        if (parentNode.querySelector(selector)) {
          clearTimeout(timeout);
          resolve(parentNode.querySelector(selector));
          observer?.disconnect();
        }
      });

      observer?.observe(parentNode, {
        childList: true,
        subtree: true,
      });
    });
  };

  const checkCanFocusTrap = async (containers) =>
    waitForElement({
      selector: 'button',
      container: containers[0],
    });

  const currentView = useSelector((state) => state.view.currentView);

  return (
    <Flex direction="row" height="100%">
      {showFilters && (
        <View flex>
          <FilterWrapper></FilterWrapper>
        </View>
      )}
      {!showFilters && selectedItems.length < 1 && currentView == VIEW_TYPES.tree && (
        <View flex>
          <FolderSidebar></FolderSidebar>
        </View>
      )}
      <View flex>
        <div
          style={{
            height: '100%',
            top: '50px',
            width: width,
            backgroundColor: '#f4f4f4',
            position: 'absolute',
          }}
        >
          <DashboardSubHeader />
          <div
            style={{ height: '100%', overflow: 'scrol' }}
            onScroll={() => {
              let obj = window.document.querySelector('#dashboard-body');
              if (obj.scrollTop === obj.scrollHeight - obj.offsetHeight) {
                if (!loading && hasMore) {
                  dispatch(setLoading(true));
                  increaseOffset();
                }
              }
            }}
          >
            <Flex
              marginY={'size-200'}
              marginX={'size-200'}
              gap={'size-200'}
              direction={'row'}
              wrap={'wrap'}
              height={'100%'}
              UNSAFE_style={{ overflow: 'scroll', padding: '10px' }}
              alignItems={'flex-start'}
              id="dashboard-body"
            >
              {listType == 'waterfall' && documents && documents.length > 0
                ? documents.map((document) => (
                    <DocumentCard
                      key={document.ID}
                      document={document}
                      version={document.currentVersion}
                    />
                  ))
                : null}
              {listType == 'list' && documents && documents.length > 0 && (
                <DocumentTable documents={documents}></DocumentTable>
              )}
              <Flex
                direction="row"
                justifyContent="center"
                width="100%"
                marginTop={'100px'}
                height={'300px'}
              >
                {loading ? <ProgressCircle label="Loading…" isIndeterminate size="L" /> : null}
              </Flex>
            </Flex>
          </div>
        </div>
        {/* this section is an invisable form for downloads to work */}
        <>
          <form
            name="bulk_download_form"
            id="bulk_download_form"
            method="post"
            action="/internal/documents/bulkDownload"
            target="bulk_download_iframe"
            style={{
              display: 'none !important',
            }}
          >
            <input
              type="hidden"
              id="downloadManifest"
              name="downloadManifest"
              autoComplete="off"
              value=""
            />
          </form>
          <iframe
            title="bulk_download_iframe"
            name="bulk_download_iframe"
            id="bulk_download_iframe"
            style={{
              display: 'none !import',
              width: '0px',
              height: '0px',
            }}
          />
        </>
      </View>
      {!showFilters && (currentView == VIEW_TYPES.summary || selectedItems.length > 0) && (
        <FocusTrap
          focusTrapOptions={{
            clickOutsideDeactivates: true,
            checkCanFocusTrap: checkCanFocusTrap,
          }}
          active={true}
        >
          <div
            className={cx(miniXContainerClassName, miniXSizeClassName['large'])}
            // style={miniX.styles}
            // onKeyDown={this.handleDialogKeyDown}
            data-testid="minix-container"
            id="minix-container-qs"
            role="dialog"
            aria-modal="true"
            // aria-label={documentSummaryLabel()}
          >
            <Suspense
              fallback={
                <div data-testid="fallbackSpinnerTestID" css="margin: auto; align-self: center;">
                  <ProgressCircle label="Loading…" isIndeterminate size="L" />
                </div>
              }
            >
              <Minix
                currentUser={currentUser}
                ID={selectedItems}
                messageForEmptySelection={'Nothing Selected'}
                objCode={Document}
                onClose={() => {
                  dispatch(clearSelectedItems());
                  if (currentView == VIEW_TYPES.summary) {
                    dispatch(setContentView());
                  }
                }}
                onDataChange={() => {
                  alert('change');
                }}
                onMinixUpdate={() => {
                  alert('update');
                }}
                // scrollToWidgetOnLoad={miniX.initialWidget}
                width={420}
                activateFocusTrap={() => {
                  alert('activate');
                }}
                deactivateFocusTrap={() => {
                  alert('deactivate');
                }}
              />
            </Suspense>
          </div>
        </FocusTrap>
      )}
    </Flex>
  );
};

DashboardBody.propTypes = {
  width: PropTypes.string,
};
