import React, { useState } from 'react';
import { Flex, TextField } from '@adobe/react-spectrum';
import FolderOutline from '@spectrum-icons/workflow/FolderOutline';
import { useDispatch } from 'react-redux';
import { setAddNewFolder } from '../../../../redux/folders/folder.actions';
import { addFolder } from '../../../../api/folders';
import { useCurrentUser } from '@wf-mfe/auth';
import PropTypes from 'prop-types';
function NewFolderItem({ parentFolderID, onClose }) {
  const [newFolderName, setNewFolderName] = useState('');
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  return (
    <Flex alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
      <Flex gap={'5px'} alignItems={'center'} marginStart={'38px'}>
        <FolderOutline size="S" />
        <TextField
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onBlur={() => {
            if (newFolderName) {
              addFolder(newFolderName, parentFolderID, currentUser.ID);
              //TODO UPDate folder list
            } else {
              if (onClose) {
                onClose();
              } else {
                dispatch(setAddNewFolder(false));
              }
            }
          }}
          value={newFolderName}
          onChange={setNewFolderName}
        ></TextField>
      </Flex>
    </Flex>
  );
}

export { NewFolderItem };

NewFolderItem.propTypes = {
  parentFolderID: PropTypes.string,
  onClose: PropTypes.func,
};
