import { Document } from 'workfront-objcodes';
import { favoriteActionTypes } from './favorite.types';

// We reduce an array of favorite object to an object indexed by the favorite id
// We do this to better access a favorite by the id
export const setFavorites = (favorites) => ({
  type: favoriteActionTypes.SET_FAVORITES,
  payload: favorites
    .filter(function (item) {
      return item.objObjCode == Document;
    })
    .map(function (item) {
      return item.objID;
    }),
});
