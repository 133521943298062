import { VIEW_TYPES } from '../../components/utils/constants';
import { viewActionTypes } from './view.types';

const initalState = {
  currentView: VIEW_TYPES.tree,
  viewOptions: localStorage.getItem('document-dashboard-view')
    ? JSON.parse(localStorage.getItem('document-dashboard-view'))
    : ['owner:name', 'docSize', 'proofID', 'proofDecision'],

  // Object structure
  rootContext: { type: 'recent', label: 'Recents' },
  listType: 'waterfall',
};

const viewReducer = (state = initalState, action) => {
  switch (action.type) {
    case viewActionTypes.VIEW_CONTENT_ONLY:
      return {
        ...state,
        currentView: VIEW_TYPES.content,
      };
    case viewActionTypes.VIEW_CONTENT_TREE:
      return {
        ...state,
        currentView: VIEW_TYPES.tree,
      };
    case viewActionTypes.VIEW_SUMMARY:
      return {
        ...state,
        currentView: VIEW_TYPES.summary,
      };
    case viewActionTypes.SET_ROOT_CONTEXT:
      return {
        ...state,
        rootContext: action.payload,
      };
    case viewActionTypes.SET_LIST_TYPE:
      return {
        ...state,
        listType: action.payload,
      };
    case viewActionTypes.DOCUMENT_INFO_FIELDS:
      localStorage.setItem('document-dashboard-view', JSON.stringify(action.payload));
      return {
        ...state,
        viewOptions: action.payload,
      };
    default:
      return state;
  }
};

export default viewReducer;
