import React from 'react';
import { Task } from 'workfront-objcodes';

import { ComboBox, Item } from '@react-spectrum/combobox';
import { Provider } from '@react-spectrum/provider';
import { defaultTheme } from '@adobe/react-spectrum';
import { useAsyncList } from '@react-stately/data';
import { search } from '../../../api/shared';
import PropTypes from 'prop-types';

function TypeAhead({ onSelection, label, objCode }) {
  const fields = objCode === Task ? ['name', 'ID', 'project'] : ['name', 'ID'];

  let list = useAsyncList({
    async load({ filterText }) {
      const isGuid = /^[\da-f]{32}$/.test(filterText);
      if (isGuid) {
        let gresp = await search(objCode, { $$LIMIT: 10, ID: filterText }, fields);

        return {
          items: gresp,
          cursor: null,
        };
      }

      const filters = {
        name: filterText,
        name_Mod: 'cicontainsall',
        lastUpdateDate_Sort: 'desc',
        $$LIMIT: 10,
      };

      //Check if Search Term is a reference ID
      const isRefNum = /^\d+$/.test(filterText);
      if (isRefNum) {
        filters['OR:refNumSearch:referenceNumber'] = Number.parseInt(filterText);
      }

      let res = await search(objCode, filters, fields);

      return {
        items: res,
        cursor: null,
      };
    },
  });

  return (
    <Provider theme={defaultTheme} colorScheme="light" UNSAFE_style={{ fontFamily: 'Adobe Clean' }}>
      <ComboBox
        direction="bottom"
        inputValue={list.filterText}
        items={list.items}
        label={label}
        loadingState={list.loadingState}
        onInputChange={list.setFilterText}
        onLoadMore={list.loadMore}
        onSelectionChange={onSelection}
        width={'100%'}
      >
        {(item) => <Item key={item.ID}>{item.name}</Item>}
      </ComboBox>
    </Provider>
  );
}

export { TypeAhead };

TypeAhead.propTypes = {
  onSelection: PropTypes.func,
  label: PropTypes.string,
  objCode: PropTypes.string,
};
