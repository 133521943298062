import React, { useEffect } from 'react';
import { Flex } from '@adobe/react-spectrum';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardBody } from './dashboard-body';
import { DashboardHeader } from './dashboard-header';
import DialogWrapper from '../dialogs/dialog-wrapper';
import { OpTask, Portfolio, Program, Project, Task, User } from 'workfront-objcodes';
import PropTypes from 'prop-types';
import { useCurrentUser } from '@wf-mfe/auth';
import { getAllFolders } from '../../api/folders';
import { setFolders, updateFolderContext } from '../../redux/folders/folder.actions';
import { VIEW_TYPES } from '../utils/constants';
import { getAllFavorties } from '../../api/favorites';
import { setFavorites } from '../../redux/favorite/favorite.actions';

export const Dashboard = ({ folderObjCode, folderObjID }) => {
  const currentUser = useCurrentUser();

  const selectedItems = useSelector((state) => state.bulkEdit.selectedItems);
  const currentView = useSelector((state) => state.view.currentView);
  const showFilters = useSelector((state) => state.filter.showFilters);
  const dispatch = useDispatch();

  const minixOffset =
    !showFilters && (currentView != VIEW_TYPES.content || selectedItems.length) > 0 ? 472 : 0;
  const filtersOffset = showFilters ? 472 : 0;
  const offset = filtersOffset + minixOffset;
  const bodyWidth = `calc(100vw - ${offset}px)`;

  useEffect(() => {
    getAllFavorties().then((result) => {
      dispatch(setFavorites(result));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // This is to set the default folder context, when we move the view into project task and issue we will
  // Be able to pass in the objcode and the id and the folders will populate correctly
  useEffect(() => {
    if (folderObjCode && folderObjID) {
      dispatch(updateFolderContext(folderObjCode, folderObjID));
      getAllFolders(folderObjCode, folderObjID).then((result) => {
        dispatch(setFolders(result));
      });
    } else {
      dispatch(updateFolderContext(User, currentUser.ID));
      getAllFolders(User, currentUser.ID).then((result) => {
        dispatch(setFolders(result));
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderObjCode, folderObjID]);

  return (
    <div
      style={{
        width: `calc(100vw - ${0}px)`,
        height: '100%',
        position: 'fixed',
      }}
    >
      <Flex height="100%">
        <Flex direction="column" width="100%">
          <Flex direction="row">
            <div style={{ zIndex: '99999', width: '100%' }}>
              <DashboardHeader />
            </div>
          </Flex>
          <Flex direction="row" width="100%" UNSAFE_style={{ overflow: 'scroll' }}>
            <DashboardBody width={bodyWidth} />
          </Flex>
        </Flex>
      </Flex>
      <DialogWrapper />
    </div>
  );
};

Dashboard.propTypes = {
  folderObjCode: PropTypes.oneOf([User, Project, Task, OpTask, Program, Portfolio]),
  folderObjID: PropTypes.string,
};
