import { sortActionTypes } from './sort.types';

export const setSortType = (sortType) => ({
  type: sortActionTypes.SET_SORT_TYPE,
  payload: sortType,
});

export const setSortDirection = (sortDirection) => ({
  type: sortActionTypes.SET_SORT_DIRECTION,
  payload: sortDirection,
});
