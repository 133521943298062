import React from 'react';
import PropTypes from 'prop-types';
import { ToggleButton, View } from '@adobe/react-spectrum';
import { DIALOG_TYPES, DOUBLE_CLICK_WINDOW, getFileTypeIcon } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '../../../../redux/dialog/dialog.actions';
import { overrideSelectedItems } from '../../../../redux/bulk-edit/bulk-edit.actions';
import { setSelectedFolder } from '../../../../redux/folders/folder.actions';
import { setRootContext } from '../../../../redux/view/view.actions';

function FolderDocumentItem({ document, indent, folderID }) {
  let timer;
  const dispatch = useDispatch();
  const folders = useSelector((state) => state.folder.folders);

  const DOCUMENT_OFFSET = 25;

  function onClick() {
    dispatch(setSelectedFolder(folders[folderID]));
    dispatch(setRootContext({ type: 'folder' }));
    dispatch(overrideSelectedItems([document.ID]));
  }

  const onClickHandler = (event) => {
    clearTimeout(timer);
    if (event.detail === 1) {
      timer = setTimeout(onClick, DOUBLE_CLICK_WINDOW);
    } else if (event.detail === 2) {
      dispatch(
        openDialog(DIALOG_TYPES.preview, {
          document,
          version: document.currentVersion,
          breadcrumbs: [],
        })
      );
    }
  };

  return (
    <View key={folderID} UNSAFE_style={{ paddingLeft: indent + DOCUMENT_OFFSET }}>
      <ToggleButton isQuiet onClick={onClickHandler}>
        {getFileTypeIcon(document.currentVersion.ext)}
        {document.name}.{document.currentVersion.ext}
      </ToggleButton>
    </View>
  );
}

export { FolderDocumentItem };
FolderDocumentItem.propTypes = {
  document: PropTypes.object,
  indent: PropTypes.number,
  folderID: PropTypes.string,
};
