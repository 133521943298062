import { favoriteActionTypes } from './favorite.types';

const initalState = {
  favorites: [],
};

const favoriteReducer = (state = initalState, action) => {
  switch (action.type) {
    case favoriteActionTypes.SET_FAVORITES:
      return {
        ...state,
        favorites: action.payload,
      };
    default:
      return state;
  }
};

export default favoriteReducer;
