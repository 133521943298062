import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Form,
  Heading,
  TextArea,
  TextField,
} from '@adobe/react-spectrum';
import { toast } from '@phoenix/all';

import { closeDialog } from '../../../redux/dialog/dialog.actions';
import { documentsResultsIsRecent } from '../../../redux/document/document.actions';
import { edit } from '../../../api/documents';

const EditDialog = () => {
  const dialogProps = useSelector((state) => state.dialog.dialogProps);
  const { document } = dialogProps;
  const [name, setName] = useState(document?.name || '');
  const [description, setDescription] = useState(document?.description || '');
  const dispatch = useDispatch();

  const onSubmit = () => {
    const promise = edit(document.ID, name, description);

    promise
      .catch((error) => {
        toast.error(error);
      })
      .then((data) => {
        if (data.error) {
          toast.error(data.error.message);
        } else {
          toast(`${name} has been edited.`);
          dispatch(documentsResultsIsRecent());
        }
      });

    dispatch(closeDialog());
  };

  const cancel = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog>
      <Heading>Edit Details</Heading>
      <Divider />
      <Content>
        <Form>
          <TextField label="name" value={name} onChange={setName} />
          <TextArea label="description" value={description} onChange={setDescription} />
        </Form>
      </Content>
      <ButtonGroup>
        <Button variant="secondary" onPress={cancel}>
          Cancel
        </Button>
        <Button variant="cta" onPress={onSubmit}>
          Submit
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

export default EditDialog;
