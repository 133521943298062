import { dialogActionTypes } from './dialog.types';

const initalState = {
  dialogID: "",
  dialogProps: {}
};

const dialogReducer = (state = initalState, action) => {
  switch (action.type) {
    case dialogActionTypes.OPEN_DIALOG:
      return {
        dialogID: action.payload.dialogID,
        dialogProps: { ...action.payload.dialogProps }
      };
    case dialogActionTypes.CLOSE_DIALOG:
      return {
        ...initalState
      };

    default:
      return state;
  }
};

export default dialogReducer;
