import { FieldType, IconsType } from '@workfront/panel-components';
import { DocumentVersion } from 'workfront-objcodes';
import { getJournalEntries, getNoteEntries } from '../../api/system-updates';
import { dateFromString } from './helper';

export async function getRootContextFilters(
  rootContext,
  selectedFolderID,
  currentUserId,
  favorites
) {
  switch (rootContext.type) {
    case 'all':
      return {};
    case 'approval':
      switch (rootContext.subType) {
        case 'document':
          return {
            ['awaitingApprovals:approverID']: currentUserId,
            ['awaitingApprovals:approverID_Mod']: 'eq',
          };
        case 'version':
          //This does a search for any version that has an awaiting approval for the current user
          return {
            ['EXISTS:A:$$OBJCODE']: DocumentVersion,
            ['EXISTS:A:awaitingApprovals:approverID']: currentUserId,
            ['EXISTS:A:documentID']: 'FIELD:ID',
          };
        case 'submitted':
          return {
            ['EXISTS:A:$$OBJCODE']: DocumentVersion,
            ['EXISTS:A:awaitingApprovals:submittedByID']: currentUserId,
            ['EXISTS:A:documentID']: 'FIELD:ID',
          };
      }
      break;
    // Recent uses a combination of notes and system updates on a document to determine what the user has
    // Recently interacted with. It pull the data from 4 weeks back
    case 'recent':
      // eslint-disable-next-line no-case-declarations
      const journals = await getJournalEntries(currentUserId);
      // eslint-disable-next-line no-case-declarations
      const notes = await getNoteEntries(currentUserId);
      // eslint-disable-next-line no-case-declarations
      let ids = notes.concat(journals);
      // eslint-disable-next-line no-case-declarations
      let sortedIds = ids.sort(function (a, b) {
        return dateFromString(a.entryDate) - dateFromString(b.entryDate);
      });
      // eslint-disable-next-line no-case-declarations
      let idList = sortedIds.map(function (item) {
        return item.objID;
      });
      return { ID: idList, ['ID_Mod']: 'in' };
    case 'favorite':
      console.log('HIII', favorites);
      return { ID: favorites, ['ID_Mod']: 'in' };
    case 'folder':
      return { ['folders:ID_Mod']: 'eq', ['folders:ID']: selectedFolderID };
    default:
      return {};
  }
}

export const QUICK_FILTER_FIELDS = [
  {
    key: 'name',
    label: 'Document Name',
    groupKey: 'document',
    groupLabel: 'Document',
    type: FieldType.TEXT,
    dataType: 'string',
  },
  {
    key: 'description',
    label: 'Document Description',
    groupKey: 'document',
    groupLabel: 'Document',
    type: FieldType.TEXT,
    dataType: 'string',
  },
  {
    key: 'ownerID',
    label: 'Owner ID',
    groupLabel: 'Document',
    groupKey: 'Document',
    type: FieldType.TEXT,
    icon: IconsType.Text,
    dataType: 'string',
  },
  {
    key: 'currentVersion:proofDeadlineDate',
    label: 'Proof Deadline Date',
    groupLabel: 'Document Version',
    groupKey: 'Document Version',
    type: FieldType.DATE_TIME,
    icon: IconsType.DATE_TIME,
    dataType: 'datetime',
  },
];

export const ADVANCED_FILTER_FIELD_GROUPS = [
  {
    key: 'DOCU',
    label: 'Document',
    fields: [
      {
        key: 'name',
        label: 'Name',
        groupLabel: 'Document',
        groupKey: 'Document',
        type: FieldType.TEXT,
        icon: IconsType.Text,
        dataType: 'string',
      },
      {
        key: 'description',
        label: 'Description',
        groupLabel: 'Document',
        groupKey: 'Document',
        type: FieldType.TEXT,
        icon: IconsType.Text,
        dataType: 'string',
      },
      {
        key: 'lastModDate',
        label: 'Last Modified Date',
        groupLabel: 'Document',
        groupKey: 'Document',
        type: FieldType.DATE_TIME,
        icon: IconsType.DATE_TIME,
        dataType: 'dateTime',
      },
      {
        key: 'lastUpdateDate',
        label: 'Last Updated Date',
        groupLabel: 'Document',
        groupKey: 'Document',
        type: FieldType.DATE_TIME,
        icon: IconsType.DATE_TIME,
        dataType: 'dateTime',
      },
      {
        key: 'taskID',
        label: 'Task ID',
        groupLabel: 'Document',
        groupKey: 'Document',
        type: FieldType.TEXT,
        icon: IconsType.Text,
        dataType: 'string',
      },
      {
        key: 'ownerID',
        label: 'Owner ID',
        groupLabel: 'Document',
        groupKey: 'Document',
        type: FieldType.TEXT,
        icon: IconsType.Text,
        dataType: 'string',
      },
      {
        key: 'userID',
        label: 'User ID',
        groupLabel: 'Document',
        groupKey: 'Document',
        type: FieldType.TEXT,
        icon: IconsType.Text,
        dataType: 'string',
      },
      {
        key: 'opTaskID',
        label: 'Issue ID',
        groupLabel: 'Document',
        groupKey: 'Document',
        type: FieldType.TEXT,
        icon: IconsType.Text,
        dataType: 'string',
      },
    ],
  },
  {
    key: 'DOCV',
    label: 'Document Version',
    fields: [
      {
        key: 'currentVersion:uploadName',
        label: 'Version Name',
        groupLabel: 'Document Version',
        groupKey: 'Document Version',
        type: FieldType.TEXT,
        icon: IconsType.Text,
        dataType: 'string',
      },
      {
        key: 'currentVersion:documentID',
        label: 'Version Document ID',
        groupLabel: 'Document Version',
        groupKey: 'Document Version',
        type: FieldType.TEXT,
        icon: IconsType.Text,
        dataType: 'string',
      },
      {
        key: 'currentVersion:docSize',
        label: 'Version Document Size',
        groupLabel: 'Document Version',
        groupKey: 'Document Version',
        type: FieldType.NUMBER,
        icon: IconsType.Text,
        dataType: 'integer',
      },
      {
        key: 'currentVersion:proofDeadlineDate',
        label: 'Proof Deadline Date',
        groupLabel: 'Document Version',
        groupKey: 'Document Version',
        type: FieldType.DATE_TIME,
        icon: IconsType.DATE_TIME,
        dataType: 'datetime',
      },
    ],
  },
];

export const CUSTOM_QUARTERS = [
  {
    ID: '5ff8bbe4000084636514cbbde86b4220',
    endDate: '2021-03-05',
    quarterLabel: 'Q1 21',
    startDate: '2020-12-07',
  },
];
