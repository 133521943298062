import { bulkEditActionTypes } from './bulk-edit.types';

const initalState = {
  isBulkEditMode: false,
  selectedItems: [],
};

const bulkEditReducer = (state = initalState, action) => {
  switch (action.type) {
    case bulkEditActionTypes.SET_BULK_EDIT_MODE:
      return {
        ...state,
        isBulkEditMode: action.payload,
      };
    case bulkEditActionTypes.ADD_TO_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: [...state.selectedItems, action.payload],
      };
    case bulkEditActionTypes.OVERRIDE_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: action.payload,
      };
    case bulkEditActionTypes.REMOVE_FROM_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: state.selectedItems.filter((id) => id !== action.payload) || [],
      };
    case bulkEditActionTypes.RESET_BULK_EDIT:
      return {
        ...initalState,
      };
    case bulkEditActionTypes.CLEAR_SELECTED_ITEMS:
      return {
        ...state,
        selectedItems: [],
      };
    default:
      return state;
  }
};

export default bulkEditReducer;
