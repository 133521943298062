import React from 'react';
import { Provider, defaultTheme } from '@adobe/react-spectrum';
import { Provider as ReduxProvider } from 'react-redux';
import { configureAppStore } from '../redux/store';

import './boards.css';
import { Dashboard } from './dashboard';
import { OpTask, Portfolio, Program, Project, Task, User } from 'workfront-objcodes';
import PropTypes from 'prop-types';

const store = configureAppStore();

export function DocumentsDashboard({ folderObjCode, folderObjID }) {
  return (
    <ReduxProvider store={store}>
      <Provider
        theme={defaultTheme}
        colorScheme="light"
        UNSAFE_style={{ fontFamily: 'Adobe Clean' }}
      >
        <Dashboard folderObjCode={folderObjCode} folderObjID={folderObjID} />
      </Provider>
    </ReduxProvider>
  );
}

DocumentsDashboard.propTypes = {
  folderObjCode: PropTypes.oneOf([User, Project, Task, OpTask, Program, Portfolio]),
  folderObjID: PropTypes.string,
};
