import { StreamAPI } from '@marvel/api';
import { Document, JournalEntry, Note } from 'workfront-objcodes';

// These are system updates
export const getJournalEntries = async (userId) => {
  return await StreamAPI.search({
    objCode: JournalEntry,
    filters: {
      objObjCode_Mod: 'eq',
      objObjCode: Document,
      $$LIMIT: 50,
      editedByID: userId,
      editedByID_Mod: 'eq',
      entryDate_Mod: 'gt',
      entryDate: '$$TODAYb-4w',
    },
    fields: ['objID', 'entryDate'],
  }).catch((error) => {
    console.log(`fail getting documents: ${error}`);
  });
};

// These are comments in the update stream
export const getNoteEntries = async (userId) => {
  return await StreamAPI.search({
    objCode: Note,
    filters: {
      noteObjCode_Mod: 'eq',
      noteObjCode: Document,
      $$LIMIT: 50,
      ownerID: userId,
      ownerID_Mod: 'eq',
      entryDate_Mod: 'gt',
      entryDate: '$$TODAYb-4w',
    },
    fields: ['objID', 'entryDate'],
  }).catch((error) => {
    console.log(`fail getting documents: ${error}`);
  });
};
