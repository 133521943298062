import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Flex, Item, Text, View, Picker, Link } from '@adobe/react-spectrum';
import Edit from '@spectrum-icons/workflow/Edit';
import OpenInLight from '@spectrum-icons/workflow/OpenInLight';
import Comment from '@spectrum-icons/workflow/Comment';
import { useSelector } from 'react-redux';
import CardDocumentInfoField from '../card-document-info-field';
import CardProofInfoField from '../card-proof-info-field';
import CardDocumentVersionInfoField from '../card-document-version-info-field';
import { getHumanTime } from '../../../utils/helper';

const CardDocumentInfo = ({ selectedVersion, document, setSelectedVersion, proofTemplateData }) => {
  const viewOptions = useSelector((state) => state.view.viewOptions);

  return (
    <View padding={'size-300'}>
      <Text>
        IMAGE
        <br />
        <Flex gap={'10px'} alignItems={'center'}>
          <Link>
            <a href={`document/${document.ID}/${selectedVersion.ID}/details`}>
              {document.name}.{selectedVersion.ext}
            </a>
          </Link>
          <a
            href={`document/${document.ID}/${selectedVersion.ID}/details`}
            target={'_blank'}
            rel="noreferrer"
          >
            <OpenInLight size="S" />
          </a>
        </Flex>
      </Text>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginTop="size-200"
        marginBottom="size-200"
      >
        <Picker
          isQuiet
          width={'size-200'}
          defaultSelectedKey={selectedVersion?.ID}
          items={document.versions}
          onSelectionChange={(ID) => {
            let selectedVersion = document.versions.find((version) => version.ID == ID);
            setSelectedVersion(selectedVersion);
          }}
        >
          {(item) => <Item key={item.ID}>{`V${item.version}`}</Item>}
        </Picker>

        <View alignItems="center">
          <Flex alignItems={'center'} gap="5px">
            <Edit size="XS" />
            <Text>{getHumanTime(document.lastModDate)} ago</Text>
          </Flex>
        </View>
        <View alignItems="center">
          <Flex alignItems={'center'} gap="5px">
            <Comment size="XS" />
            <Text>5</Text>
          </Flex>
        </View>
      </Flex>

      <Flex
        alignItems="center"
        justifyContent="space-between"
        marginTop="size-200"
        marginBottom="size-200"
      >
        {viewOptions.map((option) => (
          <CardDocumentInfoField key={option} option={option} document={document} />
        ))}
        {viewOptions.map((option) => (
          <CardDocumentVersionInfoField
            key={option}
            option={option}
            selectedVersion={selectedVersion}
          />
        ))}
      </Flex>
      <Divider size="S" marginBottom="size-200" />
      {viewOptions.map((option) => (
        <CardProofInfoField
          key={option}
          option={option}
          selectedVersion={selectedVersion}
          proofTemplateData={proofTemplateData}
        />
      ))}
    </View>
  );
};

export default CardDocumentInfo;

CardDocumentInfo.propTypes = {
  selectedVersion: PropTypes.object,
  document: PropTypes.object,
  setSelectedVersion: PropTypes.func,
  proofTemplateData: PropTypes.object,
};
