import { bulkEditActionTypes } from './bulk-edit.types';

export const setIsBulkEditMode = (value) => ({
  type: bulkEditActionTypes.SET_BULK_EDIT_MODE,
  payload: value,
});

export const addToSelectedItems = (documentID) => ({
  type: bulkEditActionTypes.ADD_TO_SELECTED_ITEMS,
  payload: documentID,
});

export const overrideSelectedItems = (documentID) => ({
  type: bulkEditActionTypes.OVERRIDE_SELECTED_ITEMS,
  payload: documentID,
});

export const removeFromSelectedItems = (documentID) => {
  return {
    type: bulkEditActionTypes.REMOVE_FROM_SELECTED_ITEMS,
    payload: documentID,
  };
};
export const clearSelectedItems = () => {
  return {
    type: bulkEditActionTypes.CLEAR_SELECTED_ITEMS,
  };
};


export const resetBulkEdit = () => {
  return {
    type: bulkEditActionTypes.RESET_BULK_EDIT,
  };
};
