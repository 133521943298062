import React from 'react';

import { DialogContainer } from '@adobe/react-spectrum';
import { useSelector, useDispatch } from 'react-redux';
import { closeDialog } from '../../../redux/dialog/dialog.actions';
import { dialogSwitch } from './dialog-switch';

const DialogWrapper = () => {
  const dispatch = useDispatch();
  const dialogID = useSelector((state) => state.dialog.dialogID);
  const isDialogOpen = !!dialogID;

  return (
    <DialogContainer onDismiss={() => dispatch(closeDialog())}>
      {isDialogOpen && dialogSwitch(dialogID)}
    </DialogContainer>
  );
};

export default DialogWrapper;
