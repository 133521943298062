import React, { useState, useEffect } from 'react';
import {
  Button,
  Flex,
  Content,
  Dialog,
  Divider,
  Heading,
  Item,
  Picker,
} from '@adobe/react-spectrum';
import { TypeAhead } from '../../shared/type-ahead';
import { OpTask, Portfolio, Program, Project, Task, User } from 'workfront-objcodes';
import { useCurrentUser } from '@wf-mfe/auth';
import { DocumentUpload } from '@wf-mfe/documents';
import { createDocument } from '../../../api/documents';
// eslint-disable-next-line react/prop-types
function CreateDialog({ close }) {
  const currentUser = useCurrentUser();
  const [objCode, setObjCode] = useState(User);
  const [objID, setObjId] = useState(currentUser.ID);
  const [files, setFiles] = useState();
  function handleFileCreation() {
    for (let key in files) {
      let file = files[key];
      createDocument(file.name, objCode, objID, file.handle).then(() => {
        // alert(result);
        //TODO change context to recents
      });
    }
    close();
  }

  useEffect(() => {}, [files]);

  function getLabel(objCode) {
    switch (objCode) {
      case Project:
        return 'Project';
      case Task:
        return 'Task';
      case OpTask:
        return 'Issue';
      case Program:
        return 'Program';
      case Portfolio:
        return 'Portfolio';
      default:
        return '';
    }
  }

  return (
    <Dialog width={'540px'}>
      <Heading>Create Document</Heading>
      <Divider />
      <Content>
        <Flex direction="column">
          <Flex direction="row">
            <Picker
              defaultSelectedKey={User}
              onSelectionChange={(e) => {
                setObjCode(e);
                if (e == User) {
                  setObjId(currentUser.ID);
                }
              }}
              label="Document(s) Destination"
              margin="3px"
            >
              <Item key={User}>My Documents</Item>
              <Item key={Project}>Project</Item>
              <Item key={Task}>Task</Item>
              <Item key={OpTask}>Issue</Item>
              <Item key={Program}>Program</Item>
              <Item key={Portfolio}>Portfolio</Item>
            </Picker>
          </Flex>
          <Flex direction="column" margin="3px">
            {objCode && objCode != User && (
              <TypeAhead
                onSelection={setObjId}
                objCode={objCode}
                label={`Select ${getLabel(objCode)}`}
              />
            )}
          </Flex>
          <Flex direction="column" margin="3px">
            {objID && (
              <DocumentUpload
                onDocumentAdd={(e) => {
                  setFiles(e);
                }}
              />
            )}
          </Flex>
          <Flex direction="row" margin="3px">
            {files && files.length > 0 && (
              <Button isDisabled={!files} variant="cta" onPress={handleFileCreation}>
                Create Document
              </Button>
            )}
            <Button onPress={close}>Cancel</Button>
          </Flex>
        </Flex>
      </Content>
    </Dialog>
  );
}

export { CreateDialog };
