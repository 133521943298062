import { folderActionTypes } from './folder.types';

export const openFolder = (folderID, folderContents) => ({
  type: folderActionTypes.OPEN_FOLDER,
  payload: { folderID, folderContents },
});

export const closeFolder = (folderID) => ({
  type: folderActionTypes.CLOSE_FOLDER,
  payload: folderID,
});
export const setFolders = (folders) => ({
  type: folderActionTypes.SET_FOLDERS,
  payload: folders,
});

export const setSelectedFolder = (folder) => ({
  type: folderActionTypes.SET_SELECTED_FOLDER,
  payload: folder,
});

export const updateFolderContext = (folderObjCode, folderObjID) => ({
  type: folderActionTypes.FOLDER_CONTEXT,
  payload: { folderObjCode, folderObjID },
});

export const setAddNewFolder = (addNewFolder) => ({
  type: folderActionTypes.SET_ADD_NEW_FOLDER,
  payload: addNewFolder,
});
