import React from 'react';
import { Link } from 'react-router-dom';
import {
  ActionButton,
  Button,
  ButtonGroup,
  DialogTrigger,
  Divider,
  Flex,
  Item,
  Menu,
  MenuTrigger,
  Picker,
  SearchField,
  Section,
  Text,
  ToggleButton,
} from '@adobe/react-spectrum';
import SortOrderDown from '@spectrum-icons/workflow/SortOrderDown';
import SortOrderUp from '@spectrum-icons/workflow/SortOrderUp';
import Filter from '@spectrum-icons/workflow/Filter';
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';
import Close from '@spectrum-icons/workflow/Close';
import Workflow from '@spectrum-icons/workflow/Workflow';
import More from '@spectrum-icons/workflow/More';
import Info from '@spectrum-icons/workflow/Info';
import Download from '@spectrum-icons/workflow/Download';
import Delete from '@spectrum-icons/workflow/Delete';
import Copy from '@spectrum-icons/workflow/Copy';
import Move from '@spectrum-icons/workflow/Move';
import Rename from '@spectrum-icons/workflow/Rename';
import LockClosed from '@spectrum-icons/workflow/LockClosed';
import Share from '@spectrum-icons/workflow/Share';

import {
  DIALOG_TYPES,
  DOCUMENT_FIELDS,
  PROOF_FIELDS,
  SORT_OPTIONS,
  VERSION_FIELDS,
  VIEW_TYPES,
} from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSummaryView,
  setTreeView,
  setContentView,
  updateViewOptions,
} from '../../../redux/view/view.actions';
import { CreateDialog } from '../../dialogs/create-dialog';
import { clearSelectedItems } from '../../../redux/bulk-edit/bulk-edit.actions';
import { setShowFilters } from '../../../redux/filter/filter.actions';
import { setSortDirection, setSortType } from '../../../redux/sort/sort.actions';
import { setSearchTerm } from '../../../redux/search/search.actions';
import { useCurrentUser } from '@wf-mfe/auth';
import { openDialog } from '../../../redux/dialog/dialog.actions';

export const DashboardHeader = () => {
  const ids = useSelector((state) => state.bulkEdit.selectedItems);
  const currentView = useSelector((state) => state.view.currentView);
  const viewOptions = useSelector((state) => state.view.viewOptions);
  const showFilters = useSelector((state) => state.filter.showFilters);
  const sortType = useSelector((state) => state.sort.sortType);
  const sortDirection = useSelector((state) => state.sort.sortDirection);
  const dispatch = useDispatch();

  const currentUser = useCurrentUser();

  const handleMove = () => {
    dispatch(openDialog(DIALOG_TYPES.move));
  };
  const handleDelete = () => {
    const { ID: userID } = currentUser;
    dispatch(openDialog(DIALOG_TYPES.delete, { userID }));
  };
  const handleDownload = () => {
    dispatch(openDialog(DIALOG_TYPES.download));
  };

  const handleWorkflow = () => {
    handleTODO();
    // dispatch(openDialog(DIALOG_TYPES.workflow), {proofID: s});
  };

  const handleTODO = () => {
    alert('TODO');
  };
  return (
    <>
      {ids && ids.length > 0 && (
        <Flex
          minHeight={'50px'}
          gap="size-160"
          alignItems="center"
          width="100%"
          height="100%"
          justifyContent={'space-between'}
          UNSAFE_style={{ backgroundColor: '#386fe9' }}
        >
          <Flex alignItems={'center'}>
            <ActionButton
              staticColor="white"
              isQuiet
              onPress={() => {
                dispatch(clearSelectedItems());
              }}
            >
              <Close />
            </ActionButton>
            <Text UNSAFE_style={{ color: 'white' }}>{ids.length} Items Selected</Text>
          </Flex>
          <ButtonGroup>
            <ActionButton onPress={handleWorkflow} staticColor="white" isQuiet>
              <Workflow />
              <Text>Approval Workflow</Text>
            </ActionButton>
            <ActionButton onPress={handleTODO} staticColor="white" isQuiet>
              <Info />
              <Text>Details</Text>
            </ActionButton>
            <ActionButton onPress={handleDownload} staticColor="white" isQuiet>
              <Download />
              <Text>Download</Text>
            </ActionButton>
            <ActionButton onPress={handleDelete} staticColor="white" isQuiet>
              <Delete />
              <Text>Delete</Text>
            </ActionButton>
            <ActionButton onPress={handleTODO} staticColor="white" isQuiet>
              <Copy />
              <Text>Copy</Text>
            </ActionButton>
            <ActionButton onPress={handleMove} staticColor="white" isQuiet>
              <Move />
              <Text>Move</Text>
            </ActionButton>
            <ActionButton onPress={handleTODO} staticColor="white" isQuiet>
              <Rename />
              <Text>Rename</Text>
            </ActionButton>
            <ActionButton onPress={handleTODO} staticColor="white" isQuiet>
              <LockClosed />
              <Text>Checkout</Text>
            </ActionButton>
            <ActionButton onPress={handleTODO} staticColor="white" isQuiet>
              <Share />
              <Text>Share</Text>
            </ActionButton>
            <MenuTrigger type="popover">
              <ActionButton isQuiet staticColor="white" UNSAFE_style={{ cursor: 'pointer' }}>
                <More aria-label="More" size="S" />
              </ActionButton>
              <Menu>
                <Item>Overflow</Item>
              </Menu>
            </MenuTrigger>
          </ButtonGroup>
        </Flex>
      )}
      {ids && ids.length < 1 && (
        <Flex marginX="size-100" marginY="size-100" gap="size-160" alignItems="center" width="100%">
          <Flex width={'620px'} justifyContent={'space-between'}>
            <Picker
              isQuiet
              selectedKey={ids.length > 0 ? VIEW_TYPES.summary : currentView}
              onSelectionChange={(e) => {
                if (ids.length > 0) {
                  dispatch(clearSelectedItems());
                }
                switch (e) {
                  case VIEW_TYPES.summary:
                    dispatch(setSummaryView());
                    break;
                  case VIEW_TYPES.content:
                    dispatch(setContentView());
                    break;
                  case VIEW_TYPES.tree:
                    dispatch(setTreeView());
                    break;
                }
              }}
            >
              <Item key={VIEW_TYPES.summary}>Summary</Item>
              <Item key={VIEW_TYPES.tree}>Content Tree</Item>
              <Item key={VIEW_TYPES.content}>Content Only</Item>
            </Picker>
            <ToggleButton
              isSelected={showFilters}
              aria-label="show filters"
              onChange={() => {
                dispatch(setShowFilters(!showFilters));
              }}
            >
              <Filter />
              <Text>Filters</Text>
            </ToggleButton>
          </Flex>

          <Flex gap="size-100" alignItems="center" justifyContent={'space-between'} width={'100%'}>
            <Flex>
              <Link>
                <SearchField
                  onChange={(e) => {
                    dispatch(setSearchTerm(e));
                  }}
                />
              </Link>
            </Flex>
            <Flex gap={'16px'}>
              {/* <HeaderToolbar /> */}
              {/* <Divider size="M" orientation="vertical" height="size-300" marginTop="size-75" /> */}

              <>
                <MenuTrigger closeOnSelect={false}>
                  <ActionButton isQuiet UNSAFE_style={{ cursor: 'pointer', padding: '8px' }}>
                    <Text>Fields</Text>
                    <ChevronDown size="S" />
                  </ActionButton>
                  <Menu
                    selectionMode="multiple"
                    selectedKeys={viewOptions}
                    onSelectionChange={(e) => {
                      dispatch(updateViewOptions(e));
                    }}
                  >
                    <Section title="Document Info">
                      {Object.keys(DOCUMENT_FIELDS).map((key) => (
                        <Item key={key}>{DOCUMENT_FIELDS[key].label}</Item>
                      ))}
                      {Object.keys(VERSION_FIELDS).map((key) => (
                        <Item key={key}>{VERSION_FIELDS[key].label}</Item>
                      ))}
                    </Section>

                    <Section title="Proof Info">
                      {Object.keys(PROOF_FIELDS).map((key) => (
                        <Item key={key}>{PROOF_FIELDS[key].label}</Item>
                      ))}
                    </Section>
                  </Menu>
                </MenuTrigger>
              </>
              <Flex alignItems={'center'} gap={'5px'}>
                <Text>Sort By</Text>
                <Picker
                  isQuiet
                  selectedKey={sortType}
                  onSelectionChange={(e) => {
                    localStorage.setItem('document-dashboard-sort-type', e);
                    dispatch(setSortType(e));
                  }}
                >
                  {Object.keys(SORT_OPTIONS).map((key) => (
                    <Item key={key}>{SORT_OPTIONS[key]}</Item>
                  ))}
                </Picker>
              </Flex>
              {/* </MenuTrigger> */}
              <Divider size="M" orientation="vertical" height="size-300" marginTop="size-75" />
              {sortDirection === 'desc' && (
                <ActionButton
                  isQuiet
                  onPress={() => {
                    let direction = 'asc';
                    localStorage.setItem('document-dashboard-sort-direction', direction);
                    dispatch(setSortDirection(direction));
                  }}
                  UNSAFE_style={{ cursor: 'pointer', padding: '8px' }}
                >
                  <SortOrderDown />
                </ActionButton>
              )}
              {sortDirection === 'asc' && (
                <ActionButton
                  isQuiet
                  onPress={() => {
                    let direction = 'desc';
                    localStorage.setItem('document-dashboard-sort-direction', direction);
                    dispatch(setSortDirection(direction));
                  }}
                  UNSAFE_style={{ cursor: 'pointer', padding: '8px' }}
                >
                  <SortOrderUp />
                </ActionButton>
              )}

              <DialogTrigger>
                <Button variant="cta" UNSAFE_style={{ cursor: 'pointer' }}>
                  Add New
                </Button>
                {(close) => <CreateDialog close={close} />}
              </DialogTrigger>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};
