import React from 'react';
import PropTypes from 'prop-types';
import { useCurrentUser } from '@wf-mfe/auth';
import { DocumentApprovals } from '@proofing/sc-widgets';
import { css } from 'emotion';

const CardBadge = ({ proofTemplateData, document }) => {
  const currentUser = useCurrentUser();
  function getColor() {
    switch (proofTemplateData?.decision?.decision) {
      //Pending
      case 0:
        return '#1473e6';
      //Changes Required
      case 1:
        return '#d7373f';
      //Approved with Changes
      case 2:
        return '#DA7B12';
      //Approved
      case 3:
        return '#3da74e';
      //Not Relevant
      case 4:
        return '#4b4b4b';
      default:
        return '#1473e6';
    }
  }

  function getLabel() {
    if (proofTemplateData && proofTemplateData?.decision?.displayName) {
      if (proofTemplateData.processingStatus == 'success') {
        return proofTemplateData.decision.displayName;
      } else {
        //TODO check for failed state add check if it is done processing
        return 'Processing';
      }
    }
  }
  //TODO figure out how to handle case where there is a proof approval and document approval
  return (
    <>
      <div
        data-testid="document-approval-buttons"
        style={{
          position: 'absolute',
          top: '-10px',
          right: '-5px',
          padding: '5px',
          borderRadius: '5px',
          backgroundColor: '#e1e1e1',
        }}
        className={css`
          .approvals-comment-box {
            left: 0px;
            padding: 16px;
          }
          .approvals-comment-box #decision_reason {
            margin-bottom: 16px;
            min-height: 56px;
            min-width: 296px;
          }
          .approvals-comment-box .buttons-group {
            border: none;
            margin: 0;
            padding: 0;
          }
        `}
      >
        <DocumentApprovals
          // key={docApprovalRenderKey}
          ID={document.ID}
          userID={currentUser.ID}
          onDecisionChanged={() => {
            console.log('TODO update state');
          }}
          bare
        />
      </div>
      {proofTemplateData && proofTemplateData?.decision?.displayName ? (
        <div
          style={{
            position: 'absolute',
            top: '-10px',
            right: '-5px',
            color: 'white',
            backgroundColor: getColor(),
            borderRadius: '5px',
            padding: '4px',
            fontSize: '11px',
          }}
        >
          {getLabel()}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CardBadge;

CardBadge.propTypes = {
  document: PropTypes.object,
  selectedVersion: PropTypes.object,
  proofTemplateData: PropTypes.object,
};
