import { StreamAPI, SpringController } from '@marvel/api';
import { toast } from '@phoenix/all';
import { getObjectLink } from '@quicksilver/objectmetadata';
import { Document, DocumentProvider, User } from 'workfront-objcodes';

export const uploadDocument = async (path, objCode, objID, createProof = false) => {
  const formData = new FormData();

  formData.append('uploadedFile', path);
  formData.append('docObjCode', objCode);
  formData.append('objID', objID);
  formData.append('createProof', createProof);

  return await SpringController.post({
    method: 'post',
    url: `/internal/documents/upload`,
    formData,
  }).catch((error) => {
    console.log(`fail uploading document: ${error}`);
  });
};

export const createDocument = async (name, objCode, objID, fileHandle) => {
  return await StreamAPI.action({
    objCode: Document,
    queryString: `method=PUT&action=createDocument&name=${name}&docObjCode=${objCode}&docObjID=${objID}&fileHandle=${fileHandle}`,
  }).catch((error) => {
    console.log(`fail creating document: ${error}`);
  });
};

export const getDocuments = async (filters) => {
  return await StreamAPI.search({
    objCode: Document,
    filters: filters,
    fields: [
      '*',
      'currentVersion:*',
      'currentVersion:enteredBy:name',
      'currentVersion:permissions',
      'versions:*',
      'versions:awaitingApprovals:*',
      'versions:permissions',
      'folders:ID',
      'folders:parentID',
      'owner:name',
      'notes',
      'awaitingApprovals:*',
    ],
  }).catch((error) => {
    console.log(`fail getting documents: ${error}`);
  });
};

export const getDocumentProofTemplate = async (proofToken) => {
  return await StreamAPI.action({
    objCode: Document,
    queryString: `method=PUT&action=getDocumentProofTemplate&proofToken=${proofToken}`,
  }).catch((error) => {
    console.log(`fail getting proof stages: ${error}`);
  });
};

export const getDocumentProviders = async (filters) => {
  return await StreamAPI.search({
    objCode: DocumentProvider,
    filters,
    fields: ['*'],
  }).catch((error) => {
    console.log(`fail getting document providers: ${error}`);
  });
};

export const getBreadCrumbs = async (objID, objCode) => {
  return await SpringController.get({
    method: 'get',
    url: `/breadcrumb?objID=${objID}&objCode=${objCode}`,
  }).then((result) => {
    const breadcrumbs = result.map((breadcrumbItem) => {
      return {
        name: breadcrumbItem.name,
        objCode: breadcrumbItem.objCode,
        objID: breadcrumbItem.objID,
        link: getObjectLink({
          objCode: breadcrumbItem.objCode,
          ID: breadcrumbItem.objID,
        }).to,
        origin: document.location.origin,
      };
    });
    breadcrumbs.pop();
    return breadcrumbs;
  });
};

export const deleteDocuments = (ids, userId) => {
  return SpringController.post({
    url: `internal/documents/deleteDocumentsAndFolders`,
    data: {
      objCode: User,
      objectID: userId,
      documentAndFolderIDMap: JSON.stringify({
        DOCU: ids,
      }),
    },
  });
};

export const check = (type, id) => {
  const endpoint = `internal/document/${type}`;
  return SpringController.post({
    url: endpoint,
    data: {
      documentIDs: id,
    },
  });
};

export const download = (ids) => {
  StreamAPI.action({
    objCode: Document,
    action: 'buildDownloadManifest',
    data: {
      updates: JSON.stringify({
        documentIDMap: {
          DOCU: ids,
        },
      }),
    },
  })
    .catch((error) => {
      toast.error(error);
    })
    .then((data) => {
      document.querySelector('#downloadManifest').value = data;
      document.querySelector('#bulk_download_form').submit();
      const toastMessage = ids.length > 1 ? 'documents are' : 'document is';
      toast(`Your ${toastMessage} downloading.`);
    });
};

export const edit = (id, name, description) => {
  return SpringController.post({
    url: 'internal/document/edit',
    data: {
      objCode: Document,
      objID: id,
      form: JSON.stringify({
        objCode: Document,
        ID: id,
        name,
        description,
      }),
    },
  });
};

export const move = (ids, refObjCode, refObjId) => {
  return SpringController.post({
    url: 'internal/document/move',
    data: {
      objCode: Document,
      objIDs: ids.join(','),
      form: JSON.stringify({
        objCode: Document,
        ID: '',
        objIDs: ids.join(','),
        referenceObjCode: refObjCode,
        referenceObjID: refObjId,
        formData: JSON.stringify([
          {
            referenceObjCode: refObjCode,
          },
        ]),
      }),
    },
  });
};
