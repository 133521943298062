import { combineReducers } from 'redux';

import bulkEditReducer from './bulk-edit/bulk-edit.reducer';
import dialogReducer from './dialog/dialog.reducer';
import documentReducer from './document/document.reducer';
import favoriteReducer from './favorite/favorite.reducer';
import filterReducer from './filter/filter.reducer';
import folderReducer from './folders/folder.reducer';
import searchReducer from './search/search.reducer';
import sortReducer from './sort/sort.reducer';
import viewReducer from './view/view.reducer';

const rootReducer = combineReducers({
  bulkEdit: bulkEditReducer,
  dialog: dialogReducer,
  view: viewReducer,
  folder: folderReducer,
  document: documentReducer,
  filter: filterReducer,
  sort: sortReducer,
  search: searchReducer,
  favorite: favoriteReducer,
});

export default rootReducer;
