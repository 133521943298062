import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Cell, Column, Row, TableView, TableBody, TableHeader } from '@react-spectrum/table';
import { Provider } from '@react-spectrum/provider';
import { defaultTheme, Image, View } from '@adobe/react-spectrum';
import { DOCUMENT_FIELDS } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { overrideSelectedItems } from '../../../redux/bulk-edit/bulk-edit.actions';

function DocumentTable({ documents }) {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const isInBulkEditMode = useSelector((state) => state.bulkEdit.isBulkEditMode);
  const dispatch = useDispatch();
  let [selectedKeys, setSelectedKeys] = useState(
    new Set(useSelector((state) => state.bulkEdit.selectedItems))
  );
  const viewOptions = useSelector((state) => state.view.viewOptions);

  useEffect(() => {
    let tmpColumns = [
      { name: 'Thumbnail', uid: 'thumbnail' },
      { name: 'Name', uid: 'name' },
    ];
    viewOptions.map((option) => {
      if (DOCUMENT_FIELDS[option]) {
        tmpColumns.push({
          name: DOCUMENT_FIELDS[option]?.label ? DOCUMENT_FIELDS[option]?.label : '',
          uid: option,
        });
      }
    });
    let tmpRows = [];
    if (documents) {
      for (let key in documents) {
        let document = documents[key];
        let tmpRow = {
          id: document.ID,
          name: document.name,
          thumbnail: document.currentVersion.ID,
        };
        viewOptions.map((option) => {
          if (DOCUMENT_FIELDS[option]) {
            tmpRow[option] = DOCUMENT_FIELDS[option]?.getAttribute
              ? DOCUMENT_FIELDS[option].getAttribute(document)
              : document[option];
          }
        });
        tmpRows.push(tmpRow);
      }
    }
    setColumns(tmpColumns);
    setRows(tmpRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, viewOptions]);

  function selectItem(items) {
    if (items === 'all') {
      dispatch(overrideSelectedItems(documents.map((doc) => doc.ID)));
    } else {
      dispatch(overrideSelectedItems([...items]));
    }
    setSelectedKeys(items);
  }

  return (
    <Provider
      theme={defaultTheme}
      colorScheme="light"
      UNSAFE_style={{ fontFamily: 'Adobe Clean', display: 'flex', justifyContent: 'center' }}
      width={'100%'}
    >
      {documents && rows && rows.length > 0 && columns && columns.length > 0 && (
        <View UNSAFE_style={{ backgroundColor: 'white', padding: '20px' }} width={'90%'}>
          <TableView
            isQuiet={false}
            overflowMode="wrap"
            aria-label="Example table with dynamic content"
            selectionMode={isInBulkEditMode ? 'multiple' : 'single'}
            selectedKeys={selectedKeys}
            onSelectionChange={selectItem}
          >
            <TableHeader columns={columns}>
              {(column) => (
                <Column key={column.uid} align={column.uid === 'date' ? 'end' : 'start'}>
                  {column.name}
                </Column>
              )}
            </TableHeader>
            <TableBody items={rows}>
              {(item) => (
                <Row key={item.id}>
                  {(columnKey) =>
                    columnKey != 'thumbnail' ? (
                      <Cell>{item[columnKey]}</Cell>
                    ) : (
                      <Cell>
                        <Image
                          width="size-1600"
                          height="size-1600"
                          src={`/internal/document/thumbnail?build=${new Date().getTime()}&size=SMALL&documentVersionID=${
                            item[columnKey]
                          }`}
                        />
                      </Cell>
                    )
                  }
                </Row>
              )}
            </TableBody>
          </TableView>
        </View>
      )}
    </Provider>
  );
}
export { DocumentTable };

DocumentTable.propTypes = { documents: PropTypes.array };
