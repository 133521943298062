import { PAGINATION_LIMIT } from '../../components/utils/constants';
import { filterActionTypes } from './filter.types';

const initalState = {
  searchFilters: {},
  tritonFilters: {},
  sortFilters: {},
  baseFilters: { $$LIMIT: PAGINATION_LIMIT, $$FIRST: 0 },
  showFilters: false,
};

const filterReducer = (state = initalState, action) => {
  switch (action.type) {
    case filterActionTypes.SET_SORT_FILTERS:
      return {
        ...state,
        sortFilters: action.payload,
      };
    case filterActionTypes.SET_TRITON_FILTERS:
      return {
        ...state,
        tritonFilters: action.payload,
      };
    case filterActionTypes.SET_SEARCH_FILTERS:
      return {
        ...state,
        searchFilters: action.payload,
      };
    case filterActionTypes.SET_BASE_FILTERS:
      return {
        ...state,
        baseFilters: action.payload,
      };
    case filterActionTypes.SET_SHOW_FILTERS:
      return {
        ...state,
        showFilters: action.payload,
      };
    default:
      return state;
  }
};

export default filterReducer;
