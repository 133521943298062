import { filterActionTypes } from './filter.types';

// We reduce an array of filter object to an object indexed by the filter id
// We do this to better access a filter by the id
export const setSearchFilters = (filters) => ({
  type: filterActionTypes.SET_SEARCH_FILTERS,
  payload: filters,
});

export const setTritonFilters = (filters) => ({
  type: filterActionTypes.SET_TRITON_FILTERS,
  payload: filters,
});

export const setSortFilters = (filters) => ({
  type: filterActionTypes.SET_SORT_FILTERS,
  payload: filters,
});

export const setBaseFilters = (filters) => ({
  type: filterActionTypes.SET_BASE_FILTERS,
  payload: filters,
});

export const setShowFilters = (showFilters) => ({
  type: filterActionTypes.SET_SHOW_FILTERS,
  payload: showFilters,
});
