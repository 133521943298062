import { documentActionTypes } from './document.types';

const initalState = {
  documents: [],
  isRecent: true,
  loading: false,
  hasMore: false,
  firstValue: 0,
  reload: false,
};

const documentReducer = (state = initalState, action) => {
  switch (action.type) {
    case documentActionTypes.SET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };
    case documentActionTypes.DOCUMENTS_RESULTS_IS_RECENT:
      return {
        ...state,
        isRecent: !state.isRecent,
      };
    case documentActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case documentActionTypes.SET_HAS_MORE:
      return {
        ...state,
        hasMore: action.payload,
      };
    case documentActionTypes.SET_FIRST_VALUE:
      return {
        ...state,
        firstValue: action.payload,
      };
    case documentActionTypes.REMOVE_DOCUMENT:
      // eslint-disable-next-line no-case-declarations
      let newDocs = state.documents;
      delete newDocs[action.payload];
      return {
        ...state,
        documents: newDocs,
      };

    default:
      return state;
  }
};

export default documentReducer;
