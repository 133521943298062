import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import rootReducer from './root.reducer';

export const configureAppStore = (preloadedState) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk],
    preloadedState,
    devTools: { name: 'Document Dashboard' },
  });

  return store;
};

export default configureAppStore;
