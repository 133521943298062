import React, { useState } from 'react';
import { Task, OpTask, Project } from 'workfront-objcodes';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Heading,
  Item,
  Picker,
} from '@adobe/react-spectrum';
import { toast } from '@phoenix/all';

import { TypeAhead } from '../../shared/type-ahead';
import { move } from '../../../api/documents';
import { closeDialog } from '../../../redux/dialog/dialog.actions';
import { documentsResultsIsRecent } from '../../../redux/document/document.actions';

const MoveDialog = () => {
  const [refObjCode, setRefObjCode] = useState();
  const [refObjId, setRefObjId] = useState();
  const dispatch = useDispatch();
  const docIds = useSelector((state) => state.bulkEdit.selectedItems);

  const handleMove = () => {
    const promise = move(docIds, refObjCode, refObjId);

    promise
      .catch((error) => {
        toast.error(error);
      })
      .then((data) => {
        if (data.error) {
          toast.error(data.error.message);
        } else {
          toast('Document has been moved.');
          dispatch(documentsResultsIsRecent());
        }
      });

    dispatch(closeDialog());
  };

  const cancel = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog height={350}>
      <Heading>Move Document(s)</Heading>
      <Divider />
      <Content>
        <Picker onSelectionChange={setRefObjCode} label="Choose Object Type">
          <Item key={Project}>Project</Item>
          <Item key={Task}>Task</Item>
          <Item key={OpTask}>Issue</Item>
        </Picker>
        {refObjCode && (
          <TypeAhead onSelection={setRefObjId} objCode={refObjCode} label={`Select new parent`} />
        )}
      </Content>
      <ButtonGroup>
        <Button variant="cta" onPress={handleMove}>
          Move
        </Button>
        <Button variant="secondary" onPress={cancel}>
          Cancel
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

export default MoveDialog;
