import React, { Component } from 'react';
import { number, shape } from 'prop-types';
import { Text } from '@adobe/react-spectrum';
import { ProofProgressStyled } from './proof-progress-styled';

class ProofProgress extends Component {
  static propTypes = {
    progress: shape({
      commented: number,
      decided: number,
      opened: number,
      sent: number,
    }),
  };

  static defaultProps = {
    progress: null,
  };

  getClassNameFromProgressValue = (progressValue) => {
    switch (progressValue) {
      case 4:
        return 'progress-item orange';
      case 3:
        return 'progress-item red';
      case 2:
        return 'progress-item green';
      case 1:
      default:
        return 'progress-item gray';
    }
  };

  render() {
    const { progress } = this.props;
    return (
      <ProofProgressStyled
        data-testid="proof-progress"
        className="section proofing-progress-section"
      >
        <p>
          {progress && (
            <Text>
              <span
                data-testid="progress-sent"
                className={this.getClassNameFromProgressValue(progress.sent)}
              >
                S
              </span>{' '}
              <span
                data-testid="progress-opened"
                className={this.getClassNameFromProgressValue(progress.opened)}
              >
                O
              </span>{' '}
              <span
                data-testid="progress-commented"
                className={this.getClassNameFromProgressValue(progress.commented)}
              >
                C
              </span>{' '}
              <span
                data-testid="progress-decided"
                className={this.getClassNameFromProgressValue(progress.decided)}
              >
                D
              </span>
            </Text>
          )}
        </p>
      </ProofProgressStyled>
    );
  }
}

export { ProofProgress };
