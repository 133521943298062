import React from 'react';
import Checkmark from '@spectrum-icons/workflow/Checkmark';
import Rectangle from '@spectrum-icons/workflow/Rectangle';
import { ToggleButton } from '@adobe/react-spectrum';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToSelectedItems,
  removeFromSelectedItems,
} from '../../../../redux/bulk-edit/bulk-edit.actions';
import PropTypes from 'prop-types';

const AddCardButton = ({ documentID }) => {
  const dispatch = useDispatch();
  const selectedItems = useSelector((state) => state.bulkEdit.selectedItems);
  const isSelected = selectedItems.includes(documentID);

  const handleOnChange = () => {
    if (!isSelected) {
      dispatch(addToSelectedItems(documentID));
    } else {
      dispatch(removeFromSelectedItems(documentID));
    }
  };
  return (
    <ToggleButton isEmphasized isSelected={isSelected} onChange={handleOnChange}>
      {isSelected ? <Checkmark size="XS" /> : <Rectangle size="XS" />}
    </ToggleButton>
  );
};

export default AddCardButton;

AddCardButton.propTypes = {
  documentID: PropTypes.string,
};
