import { folderActionTypes } from './folder.types';

const initalState = {
  folders: {},
  // Object structure {folderObjCode, folderObjID}
  folderContext: {},
  selectedFolder: {},
  addNewFolder: false,
};

const folderReducer = (state = initalState, action) => {
  let newFolders = { ...state.folders };
  let folderID, folderContents;

  switch (action.type) {
    case folderActionTypes.SET_FOLDERS:
      return {
        ...state,
        folders: action.payload,
      };
    case folderActionTypes.SET_ADD_NEW_FOLDER:
      return {
        ...state,
        addNewFolder: action.payload,
      };
    case folderActionTypes.FOLDER_CONTEXT:
      return {
        ...state,
        folderContext: action.payload,
      };
    case folderActionTypes.SET_SELECTED_FOLDER:
      return {
        ...state,
        selectedFolder: action.payload,
      };
    case folderActionTypes.OPEN_FOLDER:
      folderID = action.payload.folderID;
      folderContents = action.payload.folderContents;
      if (newFolders[folderID]) {
        newFolders[folderID].open = true;
        newFolders[folderID].folderContents = folderContents;
      }
      return {
        ...state,
        folders: newFolders,
      };
    case folderActionTypes.CLOSE_FOLDER:
      folderID = action.payload;
      if (newFolders[folderID]) {
        newFolders[folderID].open = false;
      }
      return {
        ...state,
        folders: newFolders,
      };
    default:
      return state;
  }
};

export default folderReducer;
