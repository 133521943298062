import { SpringController } from '@marvel/api';
import { wfetch, encodeAsFormData } from '@wf-mfe/api';
import { DocumentFolder } from 'workfront-objcodes';

export const getAllFolders = (objCode, objID) => {
  return SpringController.post({
    url: `internal/documents/getAllFolders`,
    data: {
      objCode: objCode,
      objectID: objID,
    },
  });
};

export const getFolderContents = (
  objCode,
  objID,
  pageNumber,
  pageSize,
  documentFolderID,
  sortOrder,
  sortBy
) => {
  return SpringController.post({
    url: `internal/documents/getFolderContents`,
    data: {
      pageNumber: pageNumber,
      objCode: objCode,
      objectID: objID,
      pageSize: pageSize,
      documentFolderID: documentFolderID,
      sortOrder: sortOrder,
      sortBy: sortBy,
    },
  });
};

export const addFolder = (name, parentFolderID, userID) => {
  return wfetch(`/attask/api-internal/DOCFDR`, {
    method: 'POST',
    body: encodeAsFormData({
      parentID: parentFolderID,
      name: name,
      objCode: DocumentFolder,
      userID: userID,
    }),
  });
};

export const deleteFolder = (id) => {
  return wfetch(`/attask/api-internal/DOCFDR/${id}`, {
    method: 'delete',
  });
};

export const deleteFolderAndContents = (id, objCode, objID) => {
  return SpringController.post({
    url: `internal/documents/deleteDocumentsAndFolders`,
    data: {
      //TODO this payload doesn't come through right
      documentAndFolderIDMap: `{ "DOCFDR": ["${id}"] }`,
      objCode: objCode,
      objectID: objID,
    },
  });
};
