import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@adobe/react-spectrum';
import { DOCUMENT_FIELDS } from '../../../utils/constants';

const CardDocumentInfoField = ({ option, document }) => {
  return DOCUMENT_FIELDS[option] ? (
    <View key={option}>
      {DOCUMENT_FIELDS[option]?.getAttribute
        ? DOCUMENT_FIELDS[option].getAttribute(document)
        : document[option]}
    </View>
  ) : (
    <></>
  );
};

export default CardDocumentInfoField;

CardDocumentInfoField.propTypes = {
  option: PropTypes.string,
  document: PropTypes.object,
};
