import React from 'react';
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Heading,
  Text,
} from '@adobe/react-spectrum';
import { closeDialog } from '../../../redux/dialog/dialog.actions';
import { useDispatch, useSelector } from 'react-redux';

const TestDialog = () => {
  const dispatch = useDispatch();
  const dialogProps = useSelector((state) => state.dialog.dialogProps);

  const accept = () => {
    dispatch(closeDialog());
  };

  const cancel = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog>
      <Heading>hello {JSON.stringify(dialogProps)}</Heading>
      <Divider />
      <Content>
        <Text>Test Content</Text>
      </Content>
      <ButtonGroup>
        <Button variant="cta" onPress={accept}>
          Yes, Delete it
        </Button>
        <Button variant="secondary" onPress={cancel}>
          Cancel
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

export { TestDialog };
