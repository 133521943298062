import { documentActionTypes } from './document.types';

// We reduce an array of document object to an object indexed by the document id
// We do this to better access a document by the id
export const setDocuments = (documents) => ({
  type: documentActionTypes.SET_DOCUMENTS,
  payload: documents.reduce((docs, doc) => ({ ...docs, [doc.ID]: doc }), {}),
});

export const clearDocuments = () => ({
  type: documentActionTypes.SET_DOCUMENTS,
  payload: [],
});

export const documentsResultsIsRecent = () => ({
  type: documentActionTypes.DOCUMENTS_RESULTS_IS_RECENT,
});

export const setLoading = (loading) => ({
  type: documentActionTypes.SET_LOADING,
  payload: loading,
});

export const setHasMore = (hasMore) => ({
  type: documentActionTypes.SET_HAS_MORE,
  payload: hasMore,
});

export const setFirstValue = (firstValue) => ({
  type: documentActionTypes.SET_FIRST_VALUE,
  payload: firstValue,
});

export const removeDocument = (documentID) => ({
  type: documentActionTypes.REMOVE_DOCUMENT,
  payload: documentID,
});
