import { SpringController } from '@marvel/api';
import { Document } from 'workfront-objcodes';

export const getAllFavorties = async () => {
  return await SpringController.post({
    url: '/internal/favorite/allFavorites',
  });
};

export const addFavorite = async (documentID, documentName) => {
  return await SpringController.post({
    url: '/internal/favorite/add',
    data: {
      objCode: Document,
      objID: documentID,
      name: documentName,
    },
  });
};

export const removeFavorite = async (documentID, documentName) => {
  return await SpringController.post({
    url: '/internal/favorite/delete',
    data: {
      objCode: Document,
      objID: documentID,
      name: documentName,
    },
  });
};
