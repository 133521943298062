
import { dialogActionTypes } from './dialog.types';

export const openDialog = (dialogID, dialogProps) => ({
  type: dialogActionTypes.OPEN_DIALOG,
  payload: { dialogID, dialogProps },
});

export const closeDialog = () => ({
  type: dialogActionTypes.CLOSE_DIALOG,
});

