import React, { useState } from 'react';
import { StatusLight } from '@react-spectrum/statuslight';
import { Button } from '@react-spectrum/button';
import PropTypes from 'prop-types';
import { getStageColor } from '../../utils/helper';

function ProofStages(props) {
  const { stages } = props;
  const [seeMore, setSeeMore] = useState(false);

  return (
    <>
      {stages &&
        stages.length > 0 &&
        stages.map(
          (stage, index) =>
            (seeMore ? seeMore : index < 2) && (
              <StatusLight
                UNSAFE_style={{ fontSize: '12px' }}
                variant={getStageColor(stage?.decision?.name)}
                key={index}
              >
                {stage?.name ? stage.name : 'Basic Workflow'}: {stage?.decision?.displayName}
              </StatusLight>
            )
        )}
      {stages.length > 2 && (
        <Button
          UNSAFE_style={{ fontSize: '11px' }}
          variant="primary"
          isQuiet
          onPress={() => {
            setSeeMore(!seeMore);
          }}
        >
          {seeMore ? 'See Less' : 'See More'}
        </Button>
      )}
    </>
  );
}
export { ProofStages };

ProofStages.propTypes = {
  stages: PropTypes.array,
};
