import React from 'react';
import PropTypes from 'prop-types';
import { View } from '@adobe/react-spectrum';
import { PROOF_FIELDS } from '../../../utils/constants';

const CardProofInfoField = ({ option, selectedVersion, proofTemplateData }) => {
  return PROOF_FIELDS[option] ? (
    <View>
      {PROOF_FIELDS[option]?.getAttribute
        ? PROOF_FIELDS[option].getAttribute(selectedVersion, proofTemplateData)
        : ''}
    </View>
  ) : (
    <></>
  );
};

export default CardProofInfoField;

CardProofInfoField.propTypes = {
  option: PropTypes.string,
  proofTemplateData: PropTypes.object,
  selectedVersion: PropTypes.object,
};
