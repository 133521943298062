import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Image } from '@adobe/react-spectrum';
import { useDispatch } from 'react-redux';
import { overrideSelectedItems } from '../../../../redux/bulk-edit/bulk-edit.actions';
import { openDialog } from '../../../../redux/dialog/dialog.actions';

const CardThumbnail = ({ document, selectedVersion, breadcrumbs }) => {
  const dispatch = useDispatch();
  let timer;
  function onClick() {
    dispatch(overrideSelectedItems([document.ID]));
  }

  const onClickHandler = (event) => {
    clearTimeout(timer);
    if (event.detail === 1) {
      timer = setTimeout(onClick, 400);
    } else if (event.detail === 2) {
      dispatch(openDialog('preview', { document, version: selectedVersion, breadcrumbs }));
    }
  };
  return (
    //eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div onClick={onClickHandler}>
      <Flex alignItems="center" justifyContent="center" marginTop={'-48px'} minHeight={'200px'}>
        <Image
          src={`/internal/document/thumbnail?build=1&size=HUGE&documentVersionID=${selectedVersion.ID}`}
        />
      </Flex>
    </div>
  );
};

export default CardThumbnail;

CardThumbnail.propTypes = {
  selectedVersion: PropTypes.object,
  document: PropTypes.object,
  breadcrumbs: PropTypes.array,
};
