import React, { useState } from 'react';
import {
  ActionButton,
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Content,
  Dialog,
  Divider,
  Flex,
  Heading,
  TextArea,
  TextField,
  View,
} from '@adobe/react-spectrum';
import Copy from '@spectrum-icons/workflow/Copy';
import { closeDialog } from '../../../redux/dialog/dialog.actions';
import { useDispatch, useSelector } from 'react-redux';

function ShareProofDialog() {
  const dispatch = useDispatch();
  const dialogProps = useSelector((state) => state.dialog.dialogProps);
  const { url } = dialogProps;

  const [sendTo, setSendTo] = useState('');
  const [selected, setSelected] = useState(['public']);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const copyLink = () => {
    navigator.clipboard.writeText(url);
  };

  const checkIsDisabled = () => {
    return sendTo.length <= 0;
  };

  const cancel = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog>
      <Heading>Share proof</Heading>
      <Divider />
      <Content>
        <Flex direction="column" gap="size-200">
          <View>
            <TextField label="Copy public link" isReadOnly value={url} />
            <ActionButton onPress={copyLink}>
              <Copy />
              Copy link
            </ActionButton>
          </View>
          <View>
            <TextField
              label="Or email link to"
              placeholder="Type contact name or email address to add a recipient"
              value={sendTo}
              onChange={setSendTo}
            ></TextField>
          </View>
          <View>
            <CheckboxGroup isDisabled={checkIsDisabled()} value={selected} onChange={setSelected}>
              <Checkbox value="public"> Send public link</Checkbox>
              <Checkbox value="download"> Send download link</Checkbox>
              <Checkbox value="message"> Add custom message</Checkbox>
            </CheckboxGroup>
          </View>

          <View isHidden={!selected.includes('message')}>
            <View>
              <TextField
                label="Subject"
                placeholder="Start typing"
                value={subject}
                onChange={setSubject}
              />
            </View>
            <View>
              <TextArea label="Message" value={message} onChange={setMessage} />
            </View>
          </View>
        </Flex>
      </Content>
      <ButtonGroup>
        <Button variant="cta" isDisabled={checkIsDisabled()} onPress={cancel}>
          Send
        </Button>
        <Button variant="secondary" onPress={cancel}>
          Close
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}

export { ShareProofDialog };
