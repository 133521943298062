import React from 'react';
import { css } from 'react-emotion';
import { ListBox, Item, Section, Flex, ActionButton } from '@adobe/react-spectrum';
import { useDispatch, useSelector } from 'react-redux';
import { getFolderContents } from '../../../../api/folders';
import { FolderItem } from '../folder-item';
import { FolderDocumentItem } from '../folder-document-item';
import { Document } from 'workfront-objcodes';
import {
  closeFolder,
  openFolder,
  setAddNewFolder,
  setSelectedFolder,
} from '../../../../redux/folders/folder.actions';
import { DOUBLE_CLICK_WINDOW, FOLDER_OFFSET, PRESET_CONTEXTS } from '../../../utils/constants';
import { setRootContext } from '../../../../redux/view/view.actions';
import Add from '@spectrum-icons/workflow/Add';
import { NewFolderItem } from '../new-folder-item';

function FolderSidebar() {
  let timer;
  const folderObjCode = useSelector((state) => state.folder.folderContext.folderObjCode);
  const folderObjID = useSelector((state) => state.folder.folderContext.folderObjID);
  const folders = useSelector((state) => state.folder.folders);
  const addNewFolder = useSelector((state) => state.folder.addNewFolder);
  const rootContext = useSelector((state) => state.view.rootContext);

  const dispatch = useDispatch();

  function handleClick(folderID) {
    if (folders[folderID].open) {
      dispatch(closeFolder(folderID));
    } else {
      getFolderContents(folderObjCode, folderObjID, 1, 50, folderID, 'asc', 'name').then(
        (result) => {
          dispatch(openFolder(folderID, result));
        }
      );
    }
  }

  function handleDoubleClick(folderID) {
    dispatch(setSelectedFolder(folders[folderID]));
    dispatch(setRootContext({ type: 'folder' }));

    if (!folders[folderID].open) {
      //TODO handle more than 50 folders with a see more
      getFolderContents(folderObjCode, folderObjID, 1, 50, folderID, 'asc', 'name').then(
        (result) => {
          dispatch(openFolder(folderID, result));
        }
      );
    }
  }
  const onClickHandler = (folderID, e) => {
    clearTimeout(timer);
    if (e.detail === 1) {
      timer = setTimeout(handleClick.bind(this, folderID), DOUBLE_CLICK_WINDOW);
    } else if (e.detail === 2) {
      handleDoubleClick(folderID);
    }
  };

  function getSubFolders(folderID, indent) {
    const folder = folders[folderID];
    const subFolders = folder.children;
    return (
      <>
        <FolderItem
          key={folderID}
          folder={folder}
          indent={indent}
          handleClick={(e) => {
            onClickHandler(folderID, e);
          }}
        ></FolderItem>
        {folder.open && (
          <>
            {subFolders &&
              subFolders.length > 0 &&
              subFolders.map((subFolder) => getSubFolders(subFolder, indent + FOLDER_OFFSET))}
            {folder.folderContents &&
              folder.folderContents.length > 0 &&
              folder.folderContents.map(
                (folderItem) =>
                  folderItem.object &&
                  folderItem.object.objCode == Document && (
                    <FolderDocumentItem
                      key={folderItem.object.ID}
                      document={folderItem.object}
                      indent={indent + FOLDER_OFFSET}
                      folderID={folder.id}
                      handleClick={() => {
                        alert('clicked on document');
                      }}
                    ></FolderDocumentItem>
                  )
              )}
          </>
        )}
      </>
    );
  }

  return (
    <div id="filters-container" className={folderSidebar}>
      <ListBox
        selectionMode="single"
        width="100%"
        selectedKeys={[rootContext.type]}
        onSelectionChange={(e) => {
          const key = e.currentKey;
          dispatch(
            setRootContext({
              type: key,
              subType: key == 'approval' ? 'version' : null,
              label: PRESET_CONTEXTS[key],
            })
          );
          dispatch(setSelectedFolder({}));
        }}
      >
        <Section title="Presets">
          {Object.keys(PRESET_CONTEXTS).map((key) => (
            <Item key={key}>{PRESET_CONTEXTS[key]}</Item>
          ))}
        </Section>
        <Section
          width={'100%'}
          title={
            <Flex alignItems={'center'} gap={'5px'} width={'100%'}>
              {`${folderObjCode == 'USER' ? 'My' : folderObjCode} Folders`}{' '}
              <ActionButton
                isDisabled={addNewFolder}
                isQuiet
                onPress={() => {
                  if (!addNewFolder) {
                    dispatch(setAddNewFolder(true));
                  }
                }}
              >
                <Add size="S" />
              </ActionButton>
            </Flex>
          }
        ></Section>
      </ListBox>
      {addNewFolder && <NewFolderItem />}
      {folders &&
        folders.topLevelFolders &&
        folders.topLevelFolders.length > 0 &&
        folders.topLevelFolders.map((folderID) => getSubFolders(folderID, 0))}
    </div>
  );
}

export { FolderSidebar };

const folderSidebar = css`
  width: 472px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
`;
