import React from 'react';
import PropTypes from 'prop-types';
import { Heading, Item, MenuTrigger, ActionButton, Menu, Flex } from '@adobe/react-spectrum';
import More from '@spectrum-icons/workflow/More';
import AddCardButton from '../add-card-button';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../redux/dialog/dialog.actions';
import { DIALOG_TYPES } from '../../../utils/constants';
import { check } from '../../../../api/documents';
import { toast } from '@phoenix/all';
import { documentsResultsIsRecent } from '../../../../redux/document/document.actions';
import { useCurrentUser } from '@wf-mfe/auth';
import FavoriteButton from '../favorite-button';
const CardToolbar = ({
  selectedVersion,
  document,
  hasProof,
  isCheckedIn,
  visible,
  breadcrumbs,
}) => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();
  const handleCheck = async (type) => {
    const promise = check(type, document.ID);
    promise
      .catch((error) => {
        toast.error(error);
      })
      .then(() => {
        const inOrOut = type === 'checkIn' ? 'in' : 'out';
        toast(`Document has been checked ${inOrOut}.`);
        dispatch(documentsResultsIsRecent());
      });
  };

  const handleNonDialogAction = (action) => {
    switch (action) {
      case 'checkOut':
      case 'checkIn':
        handleCheck(action);
        break;
      default:
        break;
    }
  };

  const handleDialogAction = (action) => {
    switch (action) {
      case DIALOG_TYPES.open:
        dispatch(
          openDialog(DIALOG_TYPES.preview, { document, version: selectedVersion, breadcrumbs })
        );
        break;
      case DIALOG_TYPES.delete: {
        const { ID: userID } = currentUser;
        dispatch(openDialog(DIALOG_TYPES.delete, { userID }));
        break;
      }
      case DIALOG_TYPES.download:
        dispatch(openDialog(DIALOG_TYPES.download));
        break;
      case DIALOG_TYPES.move:
        dispatch(openDialog(DIALOG_TYPES.move));
        break;
      case DIALOG_TYPES.edit:
        dispatch(openDialog(DIALOG_TYPES.edit, { document }));
        break;
      case DIALOG_TYPES.workflow:
        dispatch(openDialog(DIALOG_TYPES.workflow, { proofID: selectedVersion.proofID }));
        break;
      //TODO get proofTemplateData
      // case DIALOG_TYPES.shareProof:
      //   dispatch(
      //     openDialog(DIALOG_TYPES.shareProof, {
      //       url: proofTemplateData.teamUrl,
      //     })
      //   );
      // break;
      default:
        break;
    }
  };
  return (
    <Heading>
      <div
        style={{
          padding: '5px',
          margin: '3px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          visibility: visible ? 'visible' : 'hidden',
        }}
      >
        <AddCardButton documentID={document.ID} />
        <Flex gap={'5px'}>
          <FavoriteButton documentID={document.ID} documentName={document.name} />
          <MenuTrigger type="popover">
            <ActionButton UNSAFE_style={{ cursor: 'pointer' }}>
              <More aria-label="More" size="S" />
            </ActionButton>
            <Menu
              onAction={(action) => {
                handleDialogAction(action);
                handleNonDialogAction(action);
              }}
            >
              <Item key="open">{`Open ${selectedVersion.proofID ? 'Proof' : ''}`}</Item>{' '}
              {selectedVersion.proofID && <Item key="workflow">Approval Workflow</Item>}
              {isCheckedIn ? <Item key="delete">Delete</Item> : null}
              <Item key="edit">Edit</Item>
              <Item key="move">Move</Item>
              {isCheckedIn ? (
                <Item key="checkOut">Check out</Item>
              ) : (
                <Item key="checkIn">Check in</Item>
              )}
              <Item key="download">Download</Item>
              {hasProof ? <Item key={'share-proof'}>Share Proof</Item> : null}
            </Menu>
          </MenuTrigger>
        </Flex>
      </div>
    </Heading>
  );
};

export default CardToolbar;

CardToolbar.propTypes = {
  selectItem: PropTypes.func,
  selectedVersion: PropTypes.object,
  document: PropTypes.object,
  hasProof: PropTypes.bool,
  isCheckedIn: PropTypes.bool,
  visible: PropTypes.bool,
  breadcrumbs: PropTypes.array,
};
