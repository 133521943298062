import React from 'react';
import {
  Flex,
  MenuTrigger,
  ActionButton,
  Menu,
  Item,
  Tabs,
  TabList,
  TabPanels,
} from '@adobe/react-spectrum';
import { useDispatch, useSelector } from 'react-redux';
import { setListType, setRootContext } from '../../../../redux/view/view.actions';
import ModernGridView from '@spectrum-icons/workflow/ModernGridView';
import ViewList from '@spectrum-icons/workflow/ViewList';
import { RootContextSelector } from './root-context-selector';

export const DashboardSubHeader = () => {
  const listType = useSelector((state) => state.view.listType);
  const rootContext = useSelector((state) => state.view.rootContext);

  const dispatch = useDispatch();

  const getIcon = () => {
    switch (listType) {
      case 'waterfall':
        return <ModernGridView />;
      case 'list':
        return <ViewList />;
    }
  };
  // TODO add a spot for late and at risk approvals, I think if we add a deadline field to awaiting approvals we will be able to connect a proof
  // stage deadline to an awaiting approval. This will allow us to solve the deadline inconsistencies in proofing
  return (
    <>
      <Flex
        marginX="size-100"
        marginY="size-100"
        gap="size-160"
        alignItems="center"
        width="100%"
        justifyContent={'space-between'}
        UNSAFE_style={{ padding: '10px' }}
      >
        <RootContextSelector />
        <MenuTrigger>
          <ActionButton isQuiet>{getIcon()}</ActionButton>
          <Menu onAction={(key) => dispatch(setListType(key))}>
            <Item key="waterfall">Waterfall Grid</Item>
            <Item key="list">List</Item>
          </Menu>
        </MenuTrigger>
      </Flex>
      {rootContext.type == 'approval' && (
        <Flex justifyContent={'center'}>
          <Tabs
            width={'80%'}
            onSelectionChange={(e) => {
              dispatch(setRootContext({ ...rootContext, subType: e }));
            }}
          >
            <TabList>
              <Item key="submitted">{"Approvals I've Submitted"}</Item>
              <Item key="document">Pending Document Approvals</Item>
              <Item key="version">Pending Version Approvals</Item>
            </TabList>
            <TabPanels>
              <Item key="document"></Item>
              <Item key="version"></Item>
              <Item key="submitted"></Item>
            </TabPanels>
          </Tabs>
        </Flex>
      )}
    </>
  );
};
