import React from 'react';
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Heading,
  Text,
} from '@adobe/react-spectrum';
import { toast } from '@phoenix/all';
import { closeDialog } from '../../../redux/dialog/dialog.actions';
import { clearSelectedItems } from '../../../redux/bulk-edit/bulk-edit.actions';
import { documentsResultsIsRecent } from '../../../redux/document/document.actions';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDocuments } from '../../../api/documents';

const DeleteDialog = () => {
  const dispatch = useDispatch();
  const selectedItems = useSelector((state) => state.bulkEdit.selectedItems);
  const dialogProps = useSelector((state) => state.dialog.dialogProps);
  const { userID } = dialogProps;

  const deleteDocument = () => {
    const promise = deleteDocuments(selectedItems, userID);

    promise
      .catch((error) => {
        toast.error(error);
        dispatch(closeDialog());
      })
      .then((data) => {
        if (data.error) {
          toast.error(data.error.message);
        } else if (data.success) {
          const label = selectedItems.length > 1 ? 'Documents have' : 'Document has';
          toast(`${label} been deleted.`);
          dispatch(clearSelectedItems());
          dispatch(documentsResultsIsRecent(false));
        }
        dispatch(closeDialog());
      });
  };

  const cancel = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog>
      <Heading>Are you sure you want to delete this?</Heading>
      <Divider />
      <Content>
        <Text>
          Are you sure you want to delete this? Deleted items will be moved to the Recycle Bin for
          30 days and can be recovered only by the system administrator.
        </Text>
      </Content>
      <ButtonGroup>
        <Button variant="cta" onPress={deleteDocument}>
          Yes, Delete it
        </Button>
        <Button variant="secondary" onPress={cancel}>
          Cancel
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

export { DeleteDialog };
