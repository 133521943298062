import { StreamAPI } from '@marvel/api';

export const search = async (objCode, filters, fields) => {
  return await StreamAPI.search({
    objCode: objCode,
    filters,
    fields,
  }).catch((error) => {
    console.log(`fail getting subtasks: ${error}`);
  });
};
